import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import './Hero.scss';

import purifyString from '../../../../../utilities/misc/purifyString';

import ResponsiveBackgroundImage from '../../../../misc/responsive/ResponsiveBackgroundImage';

const Hero = props => {
    const { firstName, lastName, title, heroBackground, windowHeight } = props;

    const heroStyle = { height: windowHeight.height };

    return (
        <header
            className='hero'
            style={heroStyle}
        >
            <ResponsiveBackgroundImage
                backgroundImagesArray={heroBackground}
            />
            <div className='copy'>
                <div className='cntnr'>
                    <h1>{ReactHtmlParser(firstName)}<br />{ReactHtmlParser(lastName)}</h1>
                    <p
                        className='title'
                        dangerouslySetInnerHTML={{ __html: purifyString(title) }}
                    />
                </div>
            </div>
        </header>
    );
};

Hero.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    heroBackground: PropTypes.array.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default Hero;