import React from 'react';
import PropTypes from 'prop-types';

const LaunchIcon = props => (
    <svg
        className={props.className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 26.78 26.78'
    >
        <path d='M23.86,26.78H2.92A2.92,2.92,0,0,1,0,23.86V2.92A2.92,2.92,0,0,1,2.92,0H13.39V3H3V23.78H23.78V13.39h3V23.86A2.92,2.92,0,0,1,23.86,26.78Z'/>
        <polygon points='16.43 0 16.43 3 21.66 3 7.24 17.42 9.36 19.54 23.78 5.12 23.78 10.36 26.78 10.36 26.78 0 16.43 0'/>
    </svg>
);

LaunchIcon.propTypes = {
    className: PropTypes.string
};

LaunchIcon.defaultProps = {
    className: 'launchIcon'
};

export default LaunchIcon;