import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './NextDetail.scss';

const NextDetail = props => {
    const { staticPage, name, href } = props;
    
    const nextText = staticPage === 'OurWorkDetail' ?
            'Next Case Study'
        : 'Next Service';

    return (
        <footer className='next-nav'>
            <div className='cntnr cntnr-half'>
                <Link to={href}>
                    <p className='pseudo-heading-small'>{nextText}</p>
                    <p className='pseudo-heading'>{name.replace(/(<([^>]+)>)/ig, ' ')}</p>
                </Link>
            </div>
        </footer>
    );
};

NextDetail.propTypes = {
    staticPage: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
};

export default NextDetail;