async function assembleApiRequest(navMenu) {
    // Get the preview token from the URL
    const matches = document.location.href.match(/\btoken=([^&]+)/);
    const token = !matches ? '' : matches[1];
    // Then forward that on whenever you are sending an API request
    const apiUrl = `/api/${navMenu}.json${(!token) ? '' : '?token=' + token}`;
    const apiRequest = await fetch(apiUrl)
        .then(response => {
            if ((response.status >= 200 && response.status < 300) || response.status === 404)
                return response.json();
            else {
                const error = new Error(`HTTP Error ${response.statusText}`);
                error.status = response.statusText;
                error.response = response;
                return error;
            }
        });
    return apiRequest;
}

export default function getNavigationMenus(navigationMenus, returnContent) {
    const combinedData = navigationMenus.reduce((navMenu, key) => Object.assign(navMenu, { [key]: {} }), {});
    // console.log('getNavigationMenus combinedData: ', combinedData);
    const apiRequests = navigationMenus.map((navMenu, i) => {
        return assembleApiRequest(navMenu);
    });
    // console.log('apiRequests: ', apiRequests);
    Promise.all(apiRequests).then((values) => {
        navigationMenus.forEach((navMenu, i) => {
            // combinedData[navMenu.property] = values[i];
            combinedData[navMenu] = values[i];
        });
        // console.log('In Promise: combinedData: ', combinedData);
        returnContent(combinedData);
    });
}