/**
 * Returns a custom object containing window.location’s pathname, a customized slug value and window.location’s hash value (if one exists).
 * @param {object} locationObj Optional. User can pass in a window.location object. If he or she doesn’t, the function will create one based on the user’s current window location.
 * @returns {object} An object containing window.location’s pathname, a modified slug value based on the pathname, and window.location’s hash value.
 */
export default function parseLocation(locationObj = window.location) {
    // console.log('locationObj', locationObj);
    const pathname = locationObj.pathname;
    const slug = locationObj.pathname.split('/').slice(1, -1).join('/');
    const hash = locationObj.hash;
    // const hash = (Number.isInteger(hashValue) && hashValue > 1) ? hashValue : null;
    const parsedLocationObj = {
        pathname,
        slug,
        hash
    };
    // console.log('parsedLocationObj: ', parsedLocationObj);
    return parsedLocationObj;
};