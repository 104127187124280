import React from 'react';
import PropTypes from 'prop-types';

import './DoubleImageBlock.scss';

const determinePositionClassNames = mainImagePosition => {
    const positionClassNames = (mainImagePosition === 'left') ?
            { imageLeftSide: ' wide', imageRightSide: ' narrow' }
        : { imageLeftSide: ' narrow', imageRightSide: ' wide' };
    return positionClassNames;
};

const Double = props => {
    // console.log('Double props: ', props);
    const { mainImagePosition, leftImage, rightImage } = props;
    const positionClassNames = (!!mainImagePosition) ?
            determinePositionClassNames(mainImagePosition)
        : '';
    return (
        <figure className='imageArea double'>
            <div className={`image leftSide${positionClassNames.imageLeftSide || ''}`}>
                <img
                    src={leftImage}
                    alt=''
                />
            </div>
            <div className={`image rightSide${positionClassNames.imageRightSide || ''}`}>
                <img
                    src={rightImage}
                    alt=''
                />
            </div>
        </figure>
    );
};

Double.propTypes = {
    mainImagePosition: PropTypes.string.isRequired,
    leftImage: PropTypes.string.isRequired,
    rightImage: PropTypes.string.isRequired
};

export default Double;