import React from 'react';
import PropTypes from 'prop-types';

import './TripleImageBlock.scss';

const Triple = props => {
    // console.log('Triple props: ', props)
    const { mainImagePosition, mainImage, topImage, bottomImage } = props;
    switch (mainImagePosition) {
        case 'left': {
            return (
                <figure className='imageArea triple mainImageLeft'>
                    <div className='rightSide'>
                        <div className='image topImage'>
                            <img
                                src={topImage}
                                alt=''
                            />
                        </div>
                        <div className='image bottomImages'>
                            <img
                                src={bottomImage}
                                alt=''
                            />
                        </div>
                    </div>
                    <div className='leftSide'>
                        <div className='image mainImage'>
                            <img
                                src={mainImage}
                                alt=''
                            />
                        </div>
                    </div>
                </figure>
            );
        }
        default: {
            return (
                <figure className='imageArea triple mainImageRight'>
                    <div className='rightSide'>
                        <div className='image mainImage'>
                            <img
                                src={mainImage}
                                alt=''
                            />
                        </div>
                    </div>
                    <div className='leftSide'>
                        <div className='image topImage'>
                            <img
                                src={topImage}
                                alt=''
                            />
                        </div>
                        <div className='image bottomImages'>
                            <img
                                src={bottomImage}
                                alt=''
                            />
                        </div>
                    </div>
                </figure>
            );
        }
    }
};

Triple.propTypes = {
    mainImagePosition: PropTypes.string.isRequired,
    mainImage: PropTypes.string.isRequired,
    topImage: PropTypes.string.isRequired,
    bottomImage: PropTypes.string.isRequired
};

export default Triple;