import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'jquery.easing';
import 'jquery-scrollify';

import getPageContent from '../../requests/getPageContent';
import jackmanStore from '../../redux';
import currentPageActionCreator from '../../redux/actionCreators/currentPageActionCreator';
import screenContentReceivedActionCreator from '../../redux/actionCreators/screenContentReceivedActionCreator';
import toneAnimatingActionCreator from '../../redux/actionCreators/toneAnimatingActionCreator';
import getBreakpoint from '../../utilities/misc/getBreakpoint';

import AnimatedScreen from '../../components/animated/AnimatedScreen';
import CurrentScreenIndicator from '../../components/animated/CurrentScreenIndicator';

class AnimatedTemplate extends React.Component {

    state = {
        showCurrentScreenIndicator: false
    };

    // Mutable properties that don’t require a state re-rendering
    screenHeight = 0;
    history = {};
    breakpoint = getBreakpoint(window.innerWidth);
    currentScreen = 0;
    direction = 'next';
    animationSequence = 'in';
    screenTransitioning = false;
    scrollifySettings = {};

    transitionScreens = nextScreen => {
        /**
         * If the index value of the next screen that Scrollify is going to scroll to is greater than the index of the current screen, set the direction class to “next”.
         * 
         * If the index value of the next screen that Scrollify is going to scroll to is less than the index of the current screen, set the direction class to “previous”.
         */
        this.direction = (nextScreen > this.currentScreen) ? 'next' : 'previous';
        /**
         * Immediately set the animation sequence class for the current screen to “out”, as the screen is scrolling “out” of view.
         */
        this.animationSequence = 'out';
        // After a duration of a half a second...
        setTimeout(() => {
            /**
             * ...set the index value of the next screen to be the index value of the current screen.
             * 
             * NOTE: You might need to update this value in the Redux store...maybe.
             */
            this.currentScreen = nextScreen;
            this.props.setCurrentScreen(this.currentScreen);
            /**
             * Set the animation sequence class for the current screen to “in”, as the screen is scrolling “into” view.
             */
            this.animationSequence = 'in';
            // After a duration of a half a second...
            setTimeout(() => {
                /**
                 * ...the screen transition has stopped, and apply the “stopped” class to the current screen.
                 */
                this.animationSequence = 'stopped';
            }, this.scrollifySettings.scrollSpeed / 2);
        }, this.scrollifySettings.scrollSpeed / 2);
    };

    /**
     * Triggers the page’s foreground elements’ CSS colour transition animations if the page is currently auto scrolling between scenes.
     * @param {boolean} toneAnimating Is the page currently scrolling between scenes?
     * @returns {void}
     */
    setScrollingState = toneAnimating => {
        jackmanStore.dispatch(toneAnimatingActionCreator(toneAnimating));
        this.screenTransitioning = toneAnimating;
        if (toneAnimating) {
            $(() => {
                /**
                 * Determine the index value of the next screen that Scrollify is going to scroll to.
                 */
                const nextScreen = parseInt($.scrollify.current()[0].id.split('-')[1]);
                this.transitionScreens(nextScreen);
            });
        }
        (!toneAnimating && this.props.showLoadingScreen) &&
            this.props.setShowLoadingScreen(toneAnimating);
    };

    enableDisableScrollify = disableScollify => {
        if (disableScollify) {
            $(() => {
                $.scrollify.disable();
            });
        } else {
            $(() => {
                if ($.scrollify.isDisabled()) {
                    $.scrollify.enable();
                }
            });
        }
    };

    setShowCurrentScreenIndicator = () => {
        this.setState({ ...this.state, showCurrentScreenIndicator: false });
    };

    /**
     * Generates an <AnimatedScreen /> component for each screen in the
     * page.
     * @param {Array<number>} screens – The number of screens in the
     * page
     * @returns {Array<AnimatedScreen>} – An <AnimatedScreen /> 
     * component for each screen 
     */
    generateScreens = screens => {
        const animatedScreens = screens.map((screen, i) => {
            /**
             * ALERT: Pay attention to how the zIndex value is generated below!
             */
            return (
                <AnimatedScreen
                    key={`animatedScreen-${i}`}
                    screen={i}
                    currentScreen={this.currentScreen}
                    scrollifyScrollSpeed={this.scrollifySettings.scrollSpeed}
                    direction={this.direction}
                    animationSequence={this.animationSequence}
                    screenTransitioning={this.screenTransitioning}
                    zIndex={`${screens.length - i}`}
                    screenType={screen.screenType}
                    leftAligned={screen.leftAligned || null}
                    mainText={screen.mainText}
                    color={screen.color}
                    backgroundType={screen.backgroundType}
                    background={screen.background}
                    social={screen.social || null}
                />
            );
        });
        return animatedScreens;
    };

    receivedPageContent = content => {
        if (!!content.error && content.error.code === 404)
            this.history.push('/');
        else if (!!content.screens) {
            jackmanStore.dispatch(screenContentReceivedActionCreator(content.screens));
            content.screens.length > 1 && !this.state.showCurrentScreenIndicator &&
                this.setState({ ...this.state, showCurrentScreenIndicator: true });
            let hash = window.location.hash;
            const hashValue = parseInt(hash.split('#')[1]);
            const totalScreens = content.screens.length;
            /**
             * If:
             *    •    the user selected one of the links in the global
             *          navigation menu, or
             *    •    the hash value in the URL isn’t a number (or
             *          doesn’t exist), or
             *    •    the hash value in the URL is greater than the
             *          total number of screens in the page...
             */
            if (
                this.props.resetHash ||
                isNaN(hashValue) ||
                (hashValue > totalScreens)
            ) {
                /**
                 * ...reset the URL’s hash value to “#1”, so Scrollify is forced to load the first screen in the page.
                 * 
                 * This apparently needs to happen just before Scrollify is initialized. I've tried doing this in numerous other places earlier in the stack and it always failed to reset Scrollify to the first screen.
                 * 
                 * This solution solved the problem regarding resetting the hash value back to “#1” whenever a global navigation menu link is selected:
                 * 
                 * https://gist.github.com/azu/36ba5a80feb857c77a3a
                 */
                const resetHashURL = window.location.href.replace(/#.*$/, '#1');
                window.history.replaceState('', document.title, resetHashURL);
            }
            $(() => $.scrollify(this.scrollifySettings));
            this.props.determineLoadingScreenState();
            hash = window.location.hash;
            // If, on page load, the user is in the first screen...
            if (!hash.split('#')[1] || hash.split('#')[1] === '1')
                // ...animate the screen’s content into view.
                this.transitionScreens(0);
        }
    };

    componentDidMount() {
        jackmanStore.subscribe(() => this.forceUpdate());
        this.history = this.props.history;
        this.currentScreen = (!!this.history.location.hash) ? parseInt(this.history.location.hash.split('#')[1]) - 1 : 0;
        this.scrollifySettings = {
            ...this.props.scrollifySettings,
            before: () => this.setScrollingState(true),
            after: () => this.setScrollingState(false)
        };
        const pathnameArray = this.history.location.pathname.split('/');
        if (pathnameArray.length >= 4)
            this.history.push(`/${pathnameArray[1]}/`);
        else {
            jackmanStore.dispatch(currentPageActionCreator(this.props.page));
            // API call for page’s content
            getPageContent(this.props.page, content => this.receivedPageContent(content));
        }
    }

    componentWillUnmount() {
        this.props.setShowLoadingScreen(true);
        $(() => {
            $.scrollify.destroy();
        });
    }

    render() {
        const {
            disableScollify,
            fadeDuration
        } = this.props;
        // ********** Redux state props: **********
        const {
            screens,
            resized
        } = jackmanStore.getState();

        this.enableDisableScrollify(disableScollify);
        const extraScrollDivStyle = {
            height: `${this.screenHeight / 2}px`
        };
        const showCurrentScreenIndicatorTest = (resized) ?
                false
            : (screens.length === 1) ?
                    false
                : true;
        if (resized) {
            $(() => {
                $.scrollify.destroy();
            });
        }

        return (
            <main>
                {
                    this.generateScreens(screens)
                }
                {
                    !resized &&
                        /**
                         * This <div> just provides an extra half 
                         * screen height so the last screen has 
                         * some room to scroll through its 
                         * animations.
                         */
                        <div style={extraScrollDivStyle}>&nbsp;</div>
                }
                {
                    // If the browser window hasn’t been resized...
                    showCurrentScreenIndicatorTest && this.state.showCurrentScreenIndicator &&
                        <CurrentScreenIndicator
                            currentScreen={this.currentScreen}
                            fadeDuration={fadeDuration}
                        />
                }
            </main>
        );
    }

}

AnimatedTemplate.propTypes = {
    history: PropTypes.object.isRequired,
    page: PropTypes.string.isRequired,
    showLoadingScreen: PropTypes.bool.isRequired,
    setShowLoadingScreen: PropTypes.func.isRequired,
    determineLoadingScreenState: PropTypes.func.isRequired,
    disableScollify: PropTypes.bool.isRequired,
    resetHash: PropTypes.bool.isRequired,
    setCurrentScreen: PropTypes.func.isRequired,
    fadeDuration: PropTypes.number.isRequired,
    scrollifySettings: PropTypes.object.isRequired
};

AnimatedTemplate.defaultProps = {
    fadeDuration: (1250 / 4) * 0.01,
    scrollifySettings: {
        section: '.screen',
        easing: 'linear',
        scrollSpeed: 1250
    }
};

export default AnimatedTemplate;