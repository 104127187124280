/**
 * Determines whether an animated screen should render the smartphone or desktop background image or video; based on the browser’s current breakpoint.
 * @param {array} media An array of URL paths to the container’s background image or video media. Array won't be longer than two strings. The first string is the desktop/default media, and the second string (if there is one) is the alternate smartphone media.
 * @param {string} currentBreakpoint The current breakpoint value; either “ smartphone” or “ desktop” or “ largeDesktop”;
 * @returns {object} A custom object containing two properties: The index value of the media array to display. Will be either “0” (desktop/default) or “1” (smartphone). Also, a className string to apply to the media if the indexValue is “1”.
 */
export default function getBackgroundMediaSettings(media, currentBreakpoint) {
    // console.log('media: ', media);
    // console.log(`currentBreakpoint: ${currentBreakpoint}`);
    const indexValue = (!!media && media.length > 1 && currentBreakpoint === 'smartphone') ? 1 : 0;
    const breakpointClassname = (!!media && media.length > 1) ? ` ${currentBreakpoint}` : '';
    const backgroundMediaSettings = {
        indexValue,
        breakpointClassname
    };
    // console.log('backgroundMediaSettings: ', backgroundMediaSettings);
    return backgroundMediaSettings;
}