import React from 'react';
import PropTypes from 'prop-types';

import './Headline.scss';

import formatText from '../../../../../utilities/misc/formatText';
import purifyString from '../../../../../utilities/misc/purifyString';

const Headline = props => {
    const {
        mainText,
        color
    } = props;
    
    const h1Text = formatText(mainText);

    return (
        <div
            className='textContent'
            style={{ color }}
        >
            <h1 dangerouslySetInnerHTML={{ __html: purifyString(h1Text) }} />
        </div>
    );
};

Headline.propTypes = {
    mainText: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired
};

export default Headline;