import React from 'react';

const SlashIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 41 48'
    >
        <g
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
            strokeLinecap='square'
        >
            <g
                className='group'
                transform='translate(-68.000000, -511.000000)'   strokeWidth='1.5'
            >
                <line
                    x1='107.5'
                    y1='512.5'
                    x2='69.5'
                    y2='557.5'
                />
            </g>
        </g>
    </svg>
);

export default SlashIcon;