import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { animateScroll as scroll } from 'react-scroll';

import './Home.scss';

// import getBreakpoint from '../../../../utilities/misc/getBreakpoint';
import determineIfSubSection from '../../../../utilities/misc/determineIfSubSection';

import DividerIcon from '../../../misc/icons/DividerIcon';

const generateSubNavigationLis = (staticPage, subNavigation) => {
    const subNavigationLis = subNavigation.map((subNav, i) => {
        return (
            <li key={`ourWorkSubNavigationLink-${i}`}>
                <Link to={subNav.href}>
                    <span>{subNav.name}</span>
                    {
                        staticPage === 'OurServicesHome' &&
                        <p>{subNav.heading.replace(/(<([^>]+)>)/ig, ' ')}</p>
                    }
                </Link>
            </li>
        );
    });
    return subNavigationLis;
};

const scrollToSponsors = event => {
    event.preventDefault();
    scroll.scrollTo(window.innerHeight, {
        duration: 500
    });
};

const OurHome = props => {
    const {
        staticPage,
        heading,
        paragraphs,
        subNavigation,
        sponsorsHeading,
        sponsorsCta,
        sponsorsLogos,
        // windowHeight
    } = props;

    const ourFlexStyle = /*!windowHeight.isSmartphone ?
            { height: windowHeight.height }
        : */null;
    const subSectionClassname = determineIfSubSection().name === 'our-services' ? 'ourServices' : null;

    return (
        <div className='ourHomeSponsorsWrapper'>
            <article
                className='ourHome'
                style={ourFlexStyle}
            >
                <div className='dividerIconWrapper'>
                    <DividerIcon />
                </div>
                <div className='textContent'>
                    <div className='headingParagraph'>
                        <div className='flex'>
                            <div>
                                <div className='heading'>
                                    <h1>
                                        {
                                            ReactHtmlParser(heading)
                                        }
                                    </h1>
                                </div>
                                <div className='paragraphs'>
                                    {
                                        ReactHtmlParser(paragraphs)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`ourNavigation${!sponsorsHeading ? ' noSponsors' : ''}`}>
                        {
                            !!subNavigation && subNavigation.length > 0 &&
                                <div className='flex'>
                                    <nav>
                                        <ul className={subSectionClassname}>
                                            {
                                                generateSubNavigationLis(staticPage, subNavigation)
                                            }
                                        </ul>
                                    </nav>
                                </div>
                        }
                    </div>
                </div>
                {
                    !!sponsorsHeading &&
                        <div className='selectPartners__buttonWrapper'>
                            <button
                                className='selectPartners__button'
                                onClick={e => scrollToSponsors(e)}
                            >
                                {
                                    ReactHtmlParser(sponsorsCta)
                                }
                            </button>
                        </div>
                }
            </article>
            {
                !!sponsorsHeading &&
                    <aside
                        className='selectPartners'
                        name='partners'
                        style={ourFlexStyle}
                    >
                        <div className='selectPartners__flexPositioner'>
                            <h2 className='selectPartners__heading'>
                                {
                                    ReactHtmlParser(sponsorsHeading)
                                }
                            </h2>
                            {
                                (!!sponsorsLogos && sponsorsLogos.length > 1/* && getBreakpoint(window.innerWidth) === 'smartphone'*/) ?
                                    <img
                                        src={sponsorsLogos[1]}
                                        className='selectPartners__logos'
                                        alt=''
                                    />
                                    : !!sponsorsLogos ?
                                        <img
                                            src={sponsorsLogos[0]}
                                            className='selectPartners__logos'
                                            alt=''
                                        />
                                        : null
                            }
                        </div>
                    </aside>
            }
        </div>
    );
};

OurHome.propTypes = {
    staticPage: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    paragraphs: PropTypes.string.isRequired,
    sponsorsHeading: PropTypes.string,
    sponsorsLogos: PropTypes.array,
    subNavigation: PropTypes.array.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default OurHome;