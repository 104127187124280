/**
 * Determines the curently selected option in a list of routes in a sub-section page’s subNavigation select menu. Used to indicate the sub-section page that the user is currently on.
 * 
 * This function is also used to determine the next “Our Work” detail link in the <OurWorkDetail />’s determineNextDetail() function.
 * @param {array} details An array of formatted, navigation objects that contain the slug for the section’s current “sub” page.
 * @param {string} page The slug string for the section’s current “sub” page.
 * @returns {number} The index value of the section’s current “sub” page in the navigation array.
 */
export default function determineCurrentDetail(details, page) {
    const currentDetailIndex = details.findIndex((detail, i) => {
        return detail.slug === page;
    });
    return currentDetailIndex;
}