import React from 'react';

import purifyString from './purifyString';

export default function assembleTextBlock(contentArray) {
    const content = contentArray.map((para, i) => {
        switch (para.format) {
            case 'heading': {
                return (
                    <h2
                        key={`h2-${i}`}
                        dangerouslySetInnerHTML={{ __html: purifyString(para.text) }}
                    />
                );
            }
            case 'blockquote': {
                return (
                    <div
                        key={`blockquote-${i}`}
                        className='blockquote'
                    >
                        <blockquote dangerouslySetInnerHTML={{ __html: purifyString(para.text) }} />
                        {
                            !!para.cite &&
                                <cite dangerouslySetInnerHTML={{ __html: purifyString(para.cite) }} />
                        }
                    </div>
                );
            }
            default: {
                return (
                    <p
                        key={`p-${i}`}
                        dangerouslySetInnerHTML={{ __html: purifyString(para.text) }}
                    />
                );
            }
        }
    });
    return content;
};