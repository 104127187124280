import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import './ContactFooter.scss';

import insertStrongIfTrue from '../../../../../utilities/misc/insertStrongIfTrue';
import purifyString from '../../../../../utilities/misc/purifyString';
import markupHeadingToStringNoParsing from '../../../../../utilities/misc/markupHeadingToStringNoParsing';

const ContactFooter = props => {
    const heading = markupHeadingToStringNoParsing(props.heading);
    const paragraphs = props.paragraphs.map((para, i) => {
        return `<p class="contact">${(!!para.heading) ? insertStrongIfTrue(para.heading.text, para.heading.bold) : ''}${(!!para.email) ? '<br /><a href="mailto:' + para.email.text + '">' + insertStrongIfTrue(para.email.text, para.email.bold) + '</a>' : ''}${(!!para.phone) ? '<br />' + insertStrongIfTrue(para.phone.text, para.phone.bold) : ''}</p>`;
    }).join('');

    return (
        <footer className='footer'>
            <div className='cntnr'>
                <h3>
                    {
                        ReactHtmlParser(heading)
                    }
                </h3>
                <div
                    className='contact-details'
                    dangerouslySetInnerHTML={{ __html: purifyString(paragraphs) }}
                />
            </div>
        </footer>
    );
};

ContactFooter.propTypes = {
    heading: PropTypes.array.isRequired,
    paragraphs: PropTypes.array.isRequired
};

export default ContactFooter;