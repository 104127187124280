import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './TextBlockWtihMedia.scss';

import getBreakpoint from '../../../../../utilities/misc/getBreakpoint';
import contentScaling from '../../../../../utilities/misc/contentScaling';
import assembleTextBlock from '../../../../../utilities/misc/assembleTextBlock';

const TextBlockWithMedia = props => {
    const mediaArea = useRef(null);
    const {
        textContent,
        mediaType,
        mediaPath,
        mediaTitle,
        mediaAlign
    } = props;

    const alignMedia = mediaAlign === 'left' ?
            ' alignMediaLeft'
        : ' alignMediaRight';
    const currentBreakpoint = getBreakpoint(window.innerWidth);
    const mediaAreaWidth = mediaArea.current ?
            mediaArea.current.getBoundingClientRect().width
        : 0;
    const fontSize = (currentBreakpoint !== 'largeDesktop') ?
            contentScaling(currentBreakpoint, mediaAreaWidth)
        : null;

    const mediaToEmbed = currentBreakpoint === 'smartphone' && mediaPath.length > 1 ?
            mediaPath[1]
        : mediaPath[0];

    return (
        <div className={`cntnr cntnr-half flexed${alignMedia}`}>
            <div className={`media ${mediaType}`}>
                <div
                    className='mediaArea'
                    ref={mediaArea}
                    style={{ fontSize }}
                >
                    {
                        mediaType === 'image' ?
                                <figure className='mediaSizer'>
                                    <img
                                        src={mediaToEmbed}
                                        alt={mediaTitle}
                                        width='100%'
                                    />
                                </figure>
                            :
                            <div className='mediaSizer'>
                                <iframe
                                    src={mediaPath}
                                    width='100%'
                                    height='100%'
                                    frameBorder='0'
                                    title={mediaTitle}
                                    allow='autoplay; fullscreen'
                                />
                            </div>
                    }
                </div>
            </div>
            <div className='text'>
                {
                    assembleTextBlock(textContent)
                }
            </div>
        </div>
    );
};

TextBlockWithMedia.propTypes = {
    textContent: PropTypes.array.isRequired,
    mediaType: PropTypes.oneOf([
        'image',
        'video'
    ]).isRequired,
    mediaPath: PropTypes.array.isRequired,
    mediaTitle: PropTypes.string.isRequired,
    mediaAlign: PropTypes.oneOf([
        'left',
        'right'
    ]).isRequired
};

TextBlockWithMedia.defaultProps = {
    mediaAlign: 'left'
};

export default TextBlockWithMedia;