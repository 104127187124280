export default async function getPageContent(page, returnContent) {
    // Get the preview token from the URL
    const matches = document.location.href.match(/\btoken=([^&]+)/);
    const token = !matches ? '' : matches[1];
    // Then forward that on whenever you are sending an API request
    const apiUrl = `/api/${page}.json${(!token) ? '' : '?token=' + token}`;
    return await fetch(apiUrl)
        .then(response => {
            if ((response.status >= 200 && response.status < 300) || response.status === 404)
                return response.json();
            else {
                const error = new Error(`HTTP Error ${response.statusText}`);
                error.status = response.statusText;
                error.response = response;
                return error;
            }
        })
        .then(returnContent);
}