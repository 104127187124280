import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './LeadersList.scss';

import purifyString from '../../../../../utilities/misc/purifyString';

import ResponsiveBackgroundImage from '../../../../misc/responsive/ResponsiveBackgroundImage';

const generateLeaderLis = list => {
    const leaderLis = list.map((li, i) => {
        return (
            <li key={`leaderLi-${i}`}>
                <Link to={li.href}>
                    <div className='responsiveBackgroundImageContainer'>
                        <ResponsiveBackgroundImage
                            backgroundImagesArray={li.thumbnails}
                        />
                    </div>
                    <h2>{li.name}</h2>
                    <p dangerouslySetInnerHTML={{ __html: purifyString(li.heading) }} />
                </Link>
            </li>
        );
    });
    return leaderLis;
};

const LeadersList = props => {
    const { list } = props;
    
    return (
        <nav className='leadersList'>
            <ul className='trio'>
                {
                    generateLeaderLis(list)
                }
            </ul>
        </nav>
    );
};

LeadersList.propTypes = {
    list: PropTypes.array.isRequired
};

export default LeadersList;