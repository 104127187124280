import React from 'react';
import PropTypes from 'prop-types';

import './Home.scss';

import Hero from './Hero';
import LeadersList from './LeadersList';
import ContactFooter from '../shared/ContactFooter';

const OurLeadersHome = props => {
    const { pc, subNavigation, footerContent, windowHeight } = props;
    
    return (
        <article className='leadership'>
            <Hero
                heading={pc.mainText.headline}
                paragraphs={pc.mainText.paragraphs}
                windowHeight={windowHeight}
            />
            <LeadersList
                list={subNavigation}
            />
            <ContactFooter
                heading={footerContent.headline}
                paragraphs={footerContent.paragraphs}
            />
        </article>
    );
};

OurLeadersHome.propTypes = {
    pc: PropTypes.object.isRequired,
    subNavigation: PropTypes.array.isRequired,
    footerContent: PropTypes.object.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default OurLeadersHome;