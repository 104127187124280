import React from 'react';

const CloseButtonLine = () => (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <g className="stroke" transform="translate(-540.000000, -30.000000)">
            <g transform="translate(540.000000, 30.000000)">
                <line x1="29.25" y1="0.75" x2="0.75" y2="29.25"></line>
                <line x1="29.25" y1="29.25" x2="0.75" y2="0.75"></line>
            </g>
        </g>
    </g>
);

export default CloseButtonLine;