import parseLocation from "./parseLocation";

/**
 * Determines whether or not the user is currently within one of the app.’s sub-sections that contains a sub-navigation menu.
 * @param {object} locationObj Optional. User can pass in a window.location object. If he or she doesn’t, the function will create one based on the user’s current window location.
 * @returns {object || null} If the user is in one of the app.’s sub-sections, an object will be returned containing the name of the sub-section and whether or not it’s the sub-section’s home page (home pages don't render their section’s sub-navigation menus). If the user isn’t in one of the app.’s sub-sections, null will be returned.
 */
export default function determineIfSubSection(locationObj = parseLocation()) {
    // console.log('locationObj: ', locationObj);
    const pathname = locationObj.pathname.split('/');
    // console.log('Sub-section pathname: ', pathname);
    // console.log('Sub-section pathname.length: ', pathname.length);
    const subSection = (
        (pathname.length > 2 && pathname[2] !== '') ||
        (pathname.length > 1 && (
            (pathname[1] === 'our-work') ||
            (pathname[1] === 'our-leaders') ||
            (pathname[1] === 'our-services') ||
            (pathname[1] === 'news'))
        )
    ) ? { name: pathname[1], isHomePage: !pathname[2] } : null;
    // console.log('subSection: ', subSection);
    return subSection;
};