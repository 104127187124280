import React from 'react';
import PropTypes from 'prop-types';

import './Detail.scss';

import Hero from './Hero';
import NextDetail from './NextDetail';
import DetailTextBlock from '../../shared/DetailTextBlock';
import ImageBlock from './ImageBlock';
import ColumnVideoImageBlock from './ColumnVideoImageBlock';
import TextBlockWithMedia from './TextBlockWithMedia';

const assembleArticle = articleArray => {
    const article = articleArray.map((art, i) => {
        switch (art.contentType) {
            case 'textBlock': {
                if (!!art.media) {
                    return (
                        <TextBlockWithMedia
                            key={`textBlockWithMedia-${i}`}
                            textContent={art.content}
                            mediaType={art.media.type}
                            mediaPath={art.media.path}
                            mediaTitle={art.media.title}
                            mediaAlign={art.media.align}
                        />
                    );
                } else {
                    return (
                        <DetailTextBlock
                            key={`textBlock-${i}`}
                            content={art.content}
                        />
                    );
                }
            }
            case 'videoBlock':
            case 'columnImageBlock': {
                return (
                    <ColumnVideoImageBlock
                        key={`columnVideoImageBlock-${i}`}
                        content={art.content}
                    />
                );
            }
            default: {
                return (
                    <ImageBlock
                        key={`imageBlock-${i}`}
                        format={art.imageBlockFormat}
                        settings={art.settings}
                    />
                );
            }
        }
    });
    return article;
};

const OurWorkDetail = props => {
    const { staticPage, pc, nextDetail, windowHeight } = props;
    
    return (
        <article className='workdetails'>
            <Hero
                client={pc.client}
                heading={pc.heading}
                heroBackground={pc.heroBackground}
                windowHeight={windowHeight}
            />
            <div className='blockContainer'>
                {
                    assembleArticle(pc.article, props.exampleVideoBlock)
                }
            </div>
            <NextDetail
                staticPage={staticPage}
                name={nextDetail.name}
                href={nextDetail.href}
            />
        </article>
    );
};

OurWorkDetail.propTypes = {
    staticPage: PropTypes.string.isRequired,
    pc: PropTypes.object.isRequired,
    nextDetail: PropTypes.object.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default OurWorkDetail;