import React from 'react';
import PropTypes from 'prop-types';

import './Detail.scss';

import Hero from './Hero';
import DetailTextBlock from '../../shared/DetailTextBlock';
import SocialFooter from './SocialFooter';
import ContactFooter from '../shared/ContactFooter';

const OurLeadersDetail = props => {
    const { pc, footerContent, windowHeight } = props;
    
    return (
        <article className='leadersdetails'>
            <Hero
                firstName={pc.firstName}
                lastName={pc.lastName}
                title={pc.title}
                heroBackground={pc.heroBackground}
                windowHeight={windowHeight}
            />
            <div className='blockContainer'>
                <DetailTextBlock
                    content={pc.article}
                />
                {
                    !!pc.social &&
                    <SocialFooter
                        text={pc.social.text}
                        networks={pc.social.networks}
                    />
                }
            </div>
            <ContactFooter
                heading={footerContent.headline}
                paragraphs={footerContent.paragraphs}
            />
        </article>
    );
};

OurLeadersDetail.propTypes = {
    pc: PropTypes.object.isRequired,
    footerContent: PropTypes.object.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default OurLeadersDetail;