import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Navigation.scss';

import convertNumberToString from '../../../../utilities/misc/convertNumberToString';
import parseLocation from '../../../../utilities/misc/parseLocation';

import HamburgerRects from '../../../misc/icons/HamburgerRects';
import CloseButtonLine from '../../../misc/icons/CloseButtonLine';
import LaunchIcon from '../../../misc/icons/LaunchIcon';

/**
 * Set the new value for the navigation’s current state class.
 * @param {string} newState – The new value for this.state.navigation (either “open” or “closed”).
 * @returns {void}
 */
const endTransition = (setNavigationPosition, newState, setTransitioning, widthAnimationDuration) => {
    setTimeout(() => {
        setNavigationPosition(newState);
        setTransitioning(false);
    }, widthAnimationDuration);
};

/**
 * Start animating the navigation menu and determine whether the navigation is opening or closing.
 * @param {ClickEvent} event – The onClick event for the <button>.
 * @returns {void}
 */
const openCloseNavigation = ({
    transitioning,
    setTransitioning,
    navigationPosition,
    setNavigationPosition,
    widthAnimationDuration,
    setNavigationOpen
}) => {
    if (!transitioning) {
        setTransitioning(true);
        switch (navigationPosition) {
            case 'closed': {
                endTransition(setNavigationPosition, 'open', setTransitioning, widthAnimationDuration);
                break;
            }
            default: {
                endTransition(setNavigationPosition, 'closed', setTransitioning, widthAnimationDuration);
                setNavigationOpen(false);
            }
        }
    }
};

const generateSubPagesLinks = pages => {
    const subPages = pages.filter(navObj => {
        return navObj.subPage;
    });
    const subPagesLinks = subPages.map((page, i) => {
        return (
            <li
                key={`subPages-${i}`}
                className={(page.slug === parseLocation().slug.split('/')[0]) ? 'current' : null}
            >
                <a
                    href={page.href}
                    target={!page.slug ? '_blank' : null}
                >
                    {page.name}
                    {
                        !page.slug &&
                        <LaunchIcon className='launch' />
                    }
                </a>
            </li>
        );
    });
    return subPagesLinks;
};

/**
 * Generates the navigation’s <li> elements for each page in the app.
 * @param {Array<Object>} pages – The Redux store’s navigation.pages array of objects for each page in the app.
 * @returns {Array<HTMLLIElement>} – An array of <li> elements for the app.’s navigation; containing their child <a> elements.
 */
const generateNavigationLinks = pages => {
    const primaryLinks = pages.filter(page => {
        return !page.subPage
    });
    const navigationLinks = primaryLinks.map((page, i) => {
        const isHomepage = parseLocation().pathname === '/' && page.slug === 'homepage';
        return (
            <li
                key={`navigation-${i}`}
                className={(page.slug === parseLocation().slug.split('/')[0] || isHomepage) ? 'current' : null}
            >
                <em>
                    <span>{convertNumberToString(i + 1)}</span>
                </em>
                    <a
                        href={page.href}
                        target={!page.slug ? '_blank' : null}
                    >
                        {page.name}
                        {
                            !page.slug &&
                                <LaunchIcon className='launch' />
                        }
                    </a>
            </li>
        );
    });
    return navigationLinks;
};

const resortPages = pages => {
    const pagesWithOrphanRemoved = pages.filter((page) => !page.orphan);
    const homeIndex = pagesWithOrphanRemoved.findIndex(pages => pages.slug === 'homepage');
    const homeEntry = pagesWithOrphanRemoved.slice(homeIndex);
    const homeEntryRemoved = pagesWithOrphanRemoved.slice(0, homeIndex);
    return [ ...homeEntry, ...homeEntryRemoved ];
};

const Navigation = props => {
    // ********** <Navigation /> props: **********
    const {
        widthAnimationDuration,
        navigation,
        setNavigationOpen
    } = props;
    // ********** useState Hooks: **********
    const [ navigationPosition, setNavigationPosition ] = useState('closed');
    const [ transitioning, setTransitioning ] = useState(false);

    const globalNavigation = {};
    globalNavigation.pages = resortPages(navigation.pages);
    globalNavigation.subPages = navigation.subPages;

    return (
        <div className='navigation'>
            <div
                className={`navigationScreen ${navigationPosition}${!!transitioning ? ' transitioning' : ''}`}
                onClick={() => openCloseNavigation({
                    transitioning,
                    setTransitioning,
                    navigationPosition,
                    setNavigationPosition,
                    widthAnimationDuration,
                    setNavigationOpen
                })}
            />
            {
                !!globalNavigation.pages && globalNavigation.pages.length > 0 &&
                    <React.Fragment>
                        <button
                            onClick={e => { e.preventDefault(); openCloseNavigation({
                                transitioning,
                                setTransitioning,
                                navigationPosition,
                                setNavigationPosition,
                                widthAnimationDuration,
                                setNavigationOpen
                            }) }}
                            aria-labelledby='label-hamburger'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 33 33'
                            >
                                <title id='label-hamburger'>Open navigation</title>
                                <HamburgerRects />
                            </svg>
                        </button>
                        <div className={`menuContactContainer ${navigationPosition}${!!transitioning ? ' transitioning' : ''}`}>
                            <div
                                className='menuContact'
                            >
                                <button
                                    onClick={e => { e.preventDefault(); openCloseNavigation({
                                        transitioning,
                                        setTransitioning,
                                        navigationPosition,
                                        setNavigationPosition,
                                        widthAnimationDuration,
                                        setNavigationOpen
                                    }) }}
                                    aria-labelledby='label-closeNavigation'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 30 30'
                                    >
                                        <title id='label-closeNavigation'>Close navigation</title>
                                        <CloseButtonLine />
                                    </svg>
                                </button>
                                <nav>
                                    <ul>
                                        {
                                            generateNavigationLinks(globalNavigation.pages)
                                        }
                                    </ul>
                                </nav>
                                <nav className='subPages'>
                                    <ul>
                                        {
                                            generateSubPagesLinks(globalNavigation.pages)
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </React.Fragment>
            }
        </div>
    );
};

Navigation.propTypes = {
    widthAnimationDuration: PropTypes.number.isRequired,
    navigation: PropTypes.object.isRequired,
    setNavigationOpen: PropTypes.func.isRequired
};

Navigation.defaultProps = {
    widthAnimationDuration: 375
};

export default Navigation;