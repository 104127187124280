/**
 * Determines the breakpoint value based on the width of the user’s browser window.
 * @param {number} windowWidth The width of the user’s browser window.
 * @returns {string} The current breakpoint.
 */
export default function getBreakpoint(windowWidth = window.innerWidth) {
    const breakpoints = {
        smartphone: 375,
        desktop: 1280,
        largeDesktop: 1920
    };
    const breakpoint = (windowWidth >= breakpoints.largeDesktop) ?
            'largeDesktop'
        : ((windowWidth < breakpoints.largeDesktop) && (windowWidth >= breakpoints.desktop)) ?
                'desktop'
            : 'smartphone';
    return breakpoint;
}