import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import './Header.scss';

import determineIfSubSection from '../../../utilities/misc/determineIfSubSection';
import determineIfLegalPage from '../../../utilities/misc/determineIfLegalPage';

import Navigation from './Navigation/';
import JackmanLogo from '../../misc/logos/JackmanLogo';

const Header = props => {
    // ********** <Header /> props: **********
    const {
        navigations,
        globalNavigationMenu,
        setNavigationOpen,
        headerHidden,
        headerDark
    } = props;
    
    const subSection = determineIfSubSection();
    const legalPage = determineIfLegalPage();
    
    return (
        <header className={`header${!!subSection && subSection.isHomePage ? ' subSectionHomePage' : !!subSection ? ' subSection' : legalPage ? ' legalPage' : ''}${(!!subSection && !subSection.isHomePage && headerHidden) ? ' hidden' : (!!subSection && !subSection.isHomePage && !headerHidden) ? ' visible' : ''}${(!!subSection && !subSection.isHomePage && headerDark) ? ' opaque' : (!!subSection && !subSection.isHomePage && !headerDark) ? ' transparent' : ''}`}>
            <div className='flexBoxContainer'>
                <div className='flexBox'>
                    <div className='column'>
                        <Route
                            render={() => <Navigation navigation={navigations[globalNavigationMenu]} setNavigationOpen={setNavigationOpen} />}
                        />
                    </div>
                    <div className='column'>
                        <a href='/'>
                            <JackmanLogo className='logo' />
                        </a>
                    </div>
                    <div className='column'>&nbsp;</div>
                </div>
            </div>
        </header>
    )
};

Header.propTypes = {
    navigations: PropTypes.object.isRequired,
    globalNavigationMenu: PropTypes.string.isRequired,
    setNavigationOpen: PropTypes.func.isRequired,
    // history: PropTypes.object.isRequired,
    headerHidden: PropTypes.bool.isRequired,
    headerDark: PropTypes.bool.isRequired
};

export default Header;