import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import './AnimatedScreen.scss';

import mediaLoadedActionCreator from '../../../redux/actionCreators/mediaLoadedActionCreator';
import getBreakpoint from '../../../utilities/misc/getBreakpoint';
import getBackgroundMediaSettings from '../../../utilities/misc/getBackgroundMediaSettings';

import ScreenTextBlock from './ScreenTextBlock';

const generateBackgroundVideo = (backgroundType, background) => {
    const video = (backgroundType === 'video') ? background : null;
    return video;
};

const generateBackgroundImage = (backgroundType, background) => {
    const image = (backgroundType === 'image') ?
        background
        : (backgroundType === 'video' && background.length > 1) ?
            [{ image: background[0].poster }, { image: background[1].poster }]
            : (backgroundType === 'video' && background.length === 1) ?
                [{ image: background[0].poster }]
                : null;
    return image;
};

const generateBackgroundColour = (backgroundType, background) => {
    const backgroundColor = !!(backgroundType === 'colour') ? background[0].colour : '#120f17';
    return backgroundColor;
};

/**
 * ALERT: Going to need to make a change to this script to accomodate screens that don't have a background image or video.
 */
/**
 * Update the Redux state when a chapter’s content has loaded; thereby 
 * making it available to scroll into.
 * @returns {void}
 */
const mediaHasLoaded = (dispatch, screen) => dispatch(mediaLoadedActionCreator(screen));

const AnimatedScreen = props => {
    const {
        screen,
        currentScreen,
        direction,
        animationSequence,
        zIndex,
        screenType,
        leftAligned,
        mainText,
        color,
        backgroundType,
        background,
        social
    } = props;
    // ********** React-Redux hooks **********
    const resized = useSelector(state => state.resized);
    const dispatch = useDispatch();

    const screenClasses = `${!!leftAligned ? 'left-aligned ' : ''}${screenType}`;
    const currentBreakpoint = getBreakpoint(window.innerWidth);
    const customStyle = {
        backgroundColor: generateBackgroundColour(backgroundType, background)
    };
    const fixedStyle = {
        ...customStyle,
        zIndex
    };
    const showInteriorContent = (screen >= currentScreen - 1) && (screen <= currentScreen + 1);
    const image = generateBackgroundImage(backgroundType, background);
    const imageBackgroundMediaSettings = getBackgroundMediaSettings(image, currentBreakpoint);
    /**
     * ALERT: You need to fix this so the image doesn't show if the video option is present...or at least test it out to see if it causes any problems if you remove the image.
     * 
     * There's a brief flash happening on Safari browsers.
     */
    const video = generateBackgroundVideo(backgroundType, background);
    const videoBackgroundMediaSettings = getBackgroundMediaSettings(video, currentBreakpoint);
    const screenTextBlockClass = (
        (currentScreen === screen) &&
        (animationSequence === 'stopped')
    ) ?
        ' currentScreen stopped'
        : (
            (currentScreen === screen) &&
            (direction === 'next') &&
            (animationSequence === 'out')
        ) ?
            ' currentScreen next out'
            : (
                (currentScreen === screen) &&
                (direction === 'next') &&
                (animationSequence === 'in')
            ) ?
                ' currentScreen next in'
                : (
                    (currentScreen === screen) &&
                    (direction === 'previous') &&
                    (animationSequence === 'out')
                ) ?
                    ' currentScreen previous out'
                    : (
                        (currentScreen === screen) &&
                        (direction === 'previous') &&
                        (animationSequence === 'in')
                    ) ?
                        ' currentScreen previous in'
                        : (
                            (currentScreen + 1 === screen)
                        ) ?
                            ' nextScreen'
                            : '';

    return (
        /**
         * NOTE: Scrollify is applying a height style, in pixels, equal to the height of the browser window to the following section.screen element.
         */
        <section
            className={`${screenClasses} screen screen-${screen}`}
            id={`screen-${screen}`}
        >
            {
                screen >= currentScreen - 1 &&
                <div
                    className={`fixed isRelative-${resized}${screenTextBlockClass}`}
                    style={!resized ? fixedStyle : customStyle}
                >
                    {/*
                        ALERT:
                        You might be able to do all of these conditions in one {&&} chunk.

                        The “src” and “className” attributes will need sophisticated conditions, but I think it's possible.
                    */}
                    {
                        !!image &&
                            <img
                                src={`${process.env.PUBLIC_URL}${image[imageBackgroundMediaSettings.indexValue].image}`}
                                alt=''
                                className={`backgroundImage${imageBackgroundMediaSettings.breakpointClassname}`}
                                onLoad={() => mediaHasLoaded(dispatch, screen)}
                            />
                    }
                    {
                        !!video &&
                            <video
                                className={`backgroundVideo${videoBackgroundMediaSettings.breakpointClassname}`}
                                autoPlay
                                muted
                                loop
                                playsInline
                                poster={`${process.env.PUBLIC_URL}${video[videoBackgroundMediaSettings.indexValue].poster}`}
                            >
                                <source
                                    src={`${process.env.PUBLIC_URL}${video[videoBackgroundMediaSettings.indexValue].video}`}
                                    type='video/mp4'
                                />
                            </video>
                    }
                    {
                        showInteriorContent &&
                            <ScreenTextBlock
                                screenType={screenType}
                                mainText={mainText}
                                color={color}
                                social={social}
                                screen={screen}
                                currentScreen={currentScreen}
                                direction={direction}
                            />
                    }
                </div>
            }
        </section>
    );
};

AnimatedScreen.propTypes = {
    screen: PropTypes.number.isRequired,
    currentScreen: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
    animationSequence: PropTypes.string.isRequired,
    zIndex: PropTypes.string.isRequired,
    screenType: PropTypes.string.isRequired,
    leftAligned: PropTypes.bool,
    mainText: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    color: PropTypes.string.isRequired,
    backgroundType: PropTypes.string.isRequired,
    background: PropTypes.array.isRequired,
    social: PropTypes.object
};

export default AnimatedScreen;