import React from 'react';
import PropTypes from 'prop-types';

import './DetailTextBlock.scss';

import assembleTextBlock from '../../../../utilities/misc/assembleTextBlock';

const DetailTextBlock = props => (
    <div className='cntnr cntnr-half'>
        {
            assembleTextBlock(props.content)
        }
    </div>
);

DetailTextBlock.propTypes = {
    content: PropTypes.array.isRequired
};

export default DetailTextBlock;
