import React from 'react';
import PropTypes from 'prop-types';

const JackmanLogo = props => (
    <svg
        className={props.className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 608 148'
    >
        <g>
            <g>
                <path
                    d='M207.8,93.1l-1.5,0.6c-3.1,7.6-11.4,14.3-22.3,14.3c-15,0-25.6-12.3-25.6-28.5c0-16.2,7.3-30.8,22.6-30.8
                    c9.2,0,16.6,5.9,16.6,15.4v2.6l1,1h5.6l1.4-1.4l-0.7-18.6l-1.1-1.5c-6-3.4-14-5.4-23-5.4c-20.9,0-41.4,14.8-41.4,40.2
                    c0,25.4,18.5,40.2,38.3,40.2c20.6,0,28.7-12.3,32.7-25.9l-0.8-1.5L207.8,93.1z M122.7,70.3c0-21.4-5.8-29.7-28.7-29.7
                    c-12.7,0-24.9,3.8-30.2,10.8C62.6,52.9,61,55.7,61,59c0,5.6,4.5,10.2,10.2,10.2c5.6,0,10.2-4.9,10.2-10.5
                    c0-11.7,7.2-14.1,15.1-13.6c6,0.4,8.3,3.7,8.7,12.1v14.6c-19.2,3.6-46.4,9.4-46.4,29.3c0,9.8,8.3,20,19.9,20
                    c12,0,22.1-7.5,26.5-13.6v14.9l26.9-13.8l-9.4-4.7V70.3z M105.1,102.1c-3.3,2.8-7.3,5.1-14,5.1c-8.4,0-13.1-4.4-13.1-12
                    c0-7.7,3.5-12.2,27.1-18.8V102.1z M512.2,113.1c-3.8,0-5.8-4-5.8-16.2V70.3c0-21.4-5.9-29.7-28.8-29.7c-17.1,0-33.9,6.8-33.9,18.7
                    c0,5.3,4.6,8.8,8.4,9.3l15.1-2.5c-0.7-0.8-2-4-2-8.8c0-7.8,6.1-12,12.5-12c6.6,0,11,4.5,11,15.2v19l-41.1,5.7
                    c-2,2-5.8,7.3-5.8,14.5c0,14.2,11.1,21.4,23.7,21.4s20.3-7,23.4-15.2c1.8,9.5,5,15.2,14.3,15.2c9.1,0,13.4-4.2,14.9-6l-1.2-3.5
                    C515.8,112.3,514.7,113.1,512.2,113.1z M488.7,91.1c0,16.2-8.9,22.2-14.1,22.2c-6.1,0-10.9-4.5-10.9-13.8c0-4.5,1-8.8,2.8-12
                    l22.1-3V91.1z M287.8,106.3l-22-35.7L281,56.3c3.6-3.3,8.3-6.2,14-7.7l4.3-1.1v-5.4l-20.2,0.8l-17.8-0.8v6.2l6.8,0.9
                    c4.9,0.6,5.7,4,2.4,7.3c-6.5,6.6-16.7,16.8-16.7,16.8h-8.7V43c0-18.8,0.5-43,0.5-43h-5l-24,5.5v3l6.3,2.4
                    c4.1,1.6,4.6,5.2,4.6,16.1v79.1c0,4.9-1.7,6.2-7.4,7.6l-4.7,1.1v4.6h42v-4.6l-4.6-1.1c-5.8-1.4-7.6-2.7-7.6-7.6V80.2h6.3
                    c0,0,17.7,29.2,22.7,39.2l27.4,0v-5.2l-4.9-1.4C292.1,111.5,290.2,110.2,287.8,106.3z M32,8.2L8.1,7.6l-0.8,6.7l15.1,2.1v85
                    c0,3.2,0,6.1-0.1,8.5c-0.1,2.5-0.2,4.6-0.3,6.4c-0.2,1.9-0.4,3.6-0.7,5c-0.3,1.5-0.8,2.9-1.3,4.3l-1.3,3H0V148h15.7l13.9-17
                    c3.1-3.8,6.6-8.2,9-13.3c2.4-5.1,3.6-11.8,3.6-19.9V16.5l14.5-2.1l-0.8-6.7L32,8.2z M598.3,112.3v-46c0-18.5-6.7-25.4-19.7-25.4
                    c-11.7,0-22.6,10.1-26.9,14.5h-0.3l-1.1-14.7h-0.8l-25.1,8.7v2.4l9.6,6v54.5l-9.6,2.7v4.4h36.3V115l-9.1-2.7V59.5
                    c3-2.4,9.1-6,15.1-6c9.4-0.2,13.5,6.3,14,15.5v43.3l-9,2.7v4.4H608v-4.3L598.3,112.3z M425.3,108.8l0-36.4
                    c0-21.8-8-31.8-22.5-31.8c-12.8-0.1-21.5,8.2-24.9,12.7c-3.7-8.8-9.4-12.7-18.8-12.7c-12,0-19.8,5.7-23.6,12.1V40.6l-26.6,13.6
                    v0.1l9,4.5v50.1c0,1.7-0.4,2.4-1.6,3.5l-7.4,6.8v0.3h35.6v-0.3l-7.4-6.8c-0.9-0.8-1.6-1.7-1.6-3.5V58.9c2.7-4.7,8.1-7.8,14.4-7.8
                    c10.2,0,13.4,8.7,13.4,25.8c0,7,0,30.2,0,31.9c0,1.7-1.1,2.7-1.9,3.5l-6.9,6.8v0.3h35.6v-0.3l-7.4-6.8c-1.2-1.1-1.7-1.7-1.7-3.5
                    V72.4c0-4.6-0.4-10.6-1.3-14.4c3.1-3.6,7.6-6.9,14.8-6.9c10.2,0,13.4,9,13.4,26.1c0,6.8,0,29.7,0,31.6c0,1.7-1.1,2.7-1.9,3.5
                    l-6.9,6.8v0.3h35.6v-0.3l-7.4-6.8C426.2,111.5,425.3,110.5,425.3,108.8z'
                />
            </g>
        </g>
    </svg>
);

JackmanLogo.propTypes = {
    className: PropTypes.string.isRequired
};

JackmanLogo.defaultProps = {
    className: 'jackmanLogo'
};

export default JackmanLogo;