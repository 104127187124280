import React from 'react';
import PropTypes from 'prop-types';

const LinkedInLogo = props => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
    >
        <path
            style={props.style}
            d='M19,0H5C2.2,0,0,2.2,0,5v14c0,2.8,2.2,5,5,5h14c2.8,0,5-2.2,5-5V5C24,2.2,21.8,0,19,0z M8,19H5V8h3V19z
        M6.5,6.7c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8S8.2,4,8.2,5S7.5,6.7,6.5,6.7z M20,19h-3v-5.6c0-3.4-4-3.1-4,0V19h-3V8h3v1.8
        c1.4-2.6,7-2.8,7,2.5V19z'
        />
    </svg>
);

LinkedInLogo.propTypes = {
    style: PropTypes.object.isRequired
};

export default LinkedInLogo;