/**
 * @param {number} screensLength The total number of screens in the page.
 * @returns {Object}
 */
function screenContentReceivedActionCreator(screens) {
    return {
        type: 'SCREEN_CONTENT_RECEIVED',
        screens
    };
}

export default screenContentReceivedActionCreator;