/**
 * Determines whether the browser is a mobile browser or a desktop 
 * browser.
 * SOURCE: https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
 * @returns {boolean} – True if the device is a mobile device. False 
 * if the device is a desktop device.
 */
function isMobileDevice() {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export default isMobileDevice;