import getPageContent from '../../requests/getPageContent';
import screenContentResetActionCreator from '../../redux/actionCreators/screenContentResetActionCreator';

function hideLoadingScreen(setShowLoadingScreen) {
    setShowLoadingScreen(false);
}

export default function requestPageContent({
    page,
    setPageContent,
    setShowLoadingScreen,
    dispatch
}) {
    getPageContent(page, content => {
        setPageContent({ ...content });
        hideLoadingScreen(setShowLoadingScreen);
        dispatch(screenContentResetActionCreator());
    });
}