export default function contentScaling(breakpoint, width) {
    // console.log(`breakpoint: ${breakpoint}, width: ${width}`);
    /** Width of the element for which we use the breakpoint’s smallest font size */
    const MIN_WIDTH = 323;
    const MAX_WIDTH = 1496.8;
    /*
    const MIN_WIDTH = 375;
    const MAX_WIDTH = 1920;
    */
	/** The font size to use when at min width */
    const FONT_SIZE = 16;

    const scale = (breakpoint === 'smartphone') ? Math.max(width / MIN_WIDTH, 1) : Math.min(width / MAX_WIDTH, 1);
    return `${FONT_SIZE * scale}px`;
}