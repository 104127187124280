import React from 'react';
import PropTypes from 'prop-types';

import './CallToAction.scss';

import insertStrongIfTrue from '../../../../../utilities/misc/insertStrongIfTrue';
import purifyString from '../../../../../utilities/misc/purifyString';
import getBreakpoint from '../../../../../utilities/misc/getBreakpoint';

const formatText = text => {
    const headingText = text.heading.text.map((head, i) => {
        return `${insertStrongIfTrue(head.text, head.bold)}${(i !== text.heading.text.length - 1) ? '<br />' : ''}`;
    }).join('');
    const paragraph = (!!text.paragraph) ?
            text.paragraph.map((para, i) => {
                return `${insertStrongIfTrue(para.text, para.bold)}${(i !== text.paragraph.length - 1) ? '<br />' : ''}`;
            }).join('')
        : null;
    return {
        heading: {
            ...text.heading,
            text: headingText
        },
        paragraph,
        cta: text.cta
    };
};

const generateCtaMask = ({
    href,
    color,
    text
}) => {
    const button = (
        <a
            href={href}
            className='button'
            target={href.includes('http') ? '_blank' : null}
            style={{
                color,
                borderColor: color
            }}
        >
            {text}
        </a>
    );
    return button;
};

const CallToAction = props => {
    const {
        mainText,
        color
    } = props;

    const ctaText = formatText(mainText);
    const logos = (!!mainText.logos && mainText.logos.length > 0) ?
            mainText.logos
        : null;

    return (
        <div
            className='textContent'
            style={{ color }}
        >
            <h1
                style={{ color: ctaText.heading.color }}
                dangerouslySetInnerHTML={{ __html: purifyString(ctaText.heading.text) }}
            />
            {
                !!ctaText.paragraph &&
                    <p dangerouslySetInnerHTML={{ __html: purifyString(ctaText.paragraph) }} />
            }
            {
                (!!logos && logos.length > 1 && getBreakpoint(window.innerWidth) === 'smartphone') ?
                        <img
                            src={logos[1]}
                            className='logos'
                            alt=''
                        />
                    : !!logos ?
                            <img
                                src={logos[0]}
                                className='logos'
                                alt=''
                            />
                        : null
            }
            {
                generateCtaMask({
                    href: ctaText.cta.href,
                    color,
                    text: ctaText.cta.text
                })
            }
        </div>
    );
};

CallToAction.propTypes = {
    mainText: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired
};

export default CallToAction;