import React from 'react';
import PropTypes from 'prop-types';

import './SocialFooter.scss';

import InstagramLogo from '../../../misc/logos/InstagramLogo';
import LinkedInLogo from '../../../misc/logos/LinkedInLogo';

const generateNetworksLis = (networks, color) => {
    const networkLis = networks.map((network, i) => {
        return (
            <li key={`network-${i}`}>
                <a
                    href={network.href}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {
                        network.network === 'instagram' &&
                        <InstagramLogo
                            style={{ fill: color }}
                        />
                    }
                    {
                        network.network === 'linkedin' &&
                            <LinkedInLogo
                                style={{ fill: color }}
                            />
                    }
                </a>
            </li>
        );
    });
    return networkLis;
};

const SocialFooter = props => {
    const { color, text, networks } = props;
    
    return (
        <div className='socialFooter'>
            <h6 style={{ color }}>{text}</h6>
            <ul className='networks'>
                {
                    generateNetworksLis(networks, color)
                }
            </ul>
        </div>
    );
};

SocialFooter.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    networks: PropTypes.array.isRequired
};

export default SocialFooter;