import React from 'react';
import PropTypes from 'prop-types';

import './TwoColumn.scss';

import formatText from '../../../../../utilities/misc/formatText';
import purifyString from '../../../../../utilities/misc/purifyString';

import DividerIcon from '../../../../misc/icons/DividerIcon';

const formatContactLinks = para => {
    const formattedContactLinks = !!para.email ?
            `<a href="mailto:${para.email.text}"><strong>${para.heading.text}</strong><br /><span>${para.email.text}</span></a>`
        : !!para.phone ?
                `<strong>${para.heading.text}</strong><br /><span>${para.phone.text}</span>`
            : !!para.web ?
                `<a href="${para.web.text}" target="_blank"><strong>${para.heading.text}</strong><br /><span>${para.web.text.replace(/^https?:\/\//i, '')}</span></a>`
                : '';
    return purifyString(formattedContactLinks);
};

const unpackMainText = mainText => {
    return (
        <React.Fragment>
            <div className='heading'>
                <div className='interiorPadding'>
                    <h1 dangerouslySetInnerHTML={{ __html: purifyString(formatText(mainText.headline)) }} />
                    {
                        mainText.leftSideParagraphs.map((para, i) => {
                            return (
                                <p
                                    key={`leftSideParagraph-${i}`}
                                    dangerouslySetInnerHTML={{ __html: purifyString(formatText([para])) }}
                                />
                            );
                        })
                    }
                </div>
            </div>
            <div className='paragraphs'>
                <div className='interiorPadding'>
                    {
                        mainText.rightSideparagraphs.map((para, i) => {
                            if (para.text) {
                                return (
                                    <p
                                        key={`twoColumnParagraph-${i}`}
                                        dangerouslySetInnerHTML={{ __html: purifyString(formatText([ para ])) }}
                                    />
                                );
                            } else {
                                return (
                                    <p
                                        className='contact'
                                        key={`twoColumnParagraph-${i}`}
                                        dangerouslySetInnerHTML={{ __html: purifyString(formatContactLinks(para)) }}
                                    />
                                );
                            }
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

const TwoColumn = props => {
    const {
        mainText,
        color
    } = props;
    
    return (
        <div
            className='textContent'
            style={{ color }}
        >
            <div className='dividerIconWrapper'>
                <DividerIcon />
            </div>
            <div className='textPositioning'>
                {
                    unpackMainText(mainText)
                }
            </div>
        </div>
    );
};

TwoColumn.propTypes = {
    mainText: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired
};

export default TwoColumn;