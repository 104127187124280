import React from 'react';
import PropTypes from 'prop-types';

const DividerIcon = props => (
    <svg
        className={props.className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 15 3000'
    >
        <path d='M8,2985V0H7V2985a7.5,7.5,0,1,0,1,0Zm-.5,14a6.5,6.5,0,1,1,6.5-6.5A6.51,6.51,0,0,1,7.5,2999Z' />
    </svg>
);

DividerIcon.propTypes = {
    className: PropTypes.string
};

DividerIcon.defaultProps = {
    className: 'dividerIcon'
};

export default DividerIcon;