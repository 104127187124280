// import ReactHtmlParser from "react-html-parser";

import purifyString from './purifyString';

/**
 * @param {string} text The text string.
 * @param {boolean} bold Should the text string be bold in weight.
 * @returns {string} A string of HTML text; either wrapped in a <strong> element
 */
/*
export default function insertStrongIfTrue(text, bold) {
    const formattedText = (!!bold) ? `<strong>${ReactHtmlParser(text)}</strong>` : ReactHtmlParser(text);
    // console.log('formattedText: ', formattedText);
    return formattedText;
}
*/
export default function insertStrongIfTrue(text, bold) {
    const formattedText = (!!bold) ? `<strong>${purifyString(text)}</strong>` : purifyString(text);
    return formattedText;
}