import { sanitize } from "dompurify";

/**
 * Clean up any creative copy that contains HTML elements to make sure there isn’t any code within it that could cause a security breach.
 * @param {string} string A string of text which may or may not contain HTML code.
 * @returns {string} A string with any un-secure HTML code in it formatted so it doesn’t cause security problems.
 */
function purifyString(string) {
    const config = { ADD_ATTR: [ 'target' ] };
    return sanitize(string, config);
}

export default purifyString;