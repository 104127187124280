import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import './CurrentScreenIndicator.scss';

import convertNumberToString from '../../../utilities/misc/convertNumberToString';

import SlashIcon from '../../misc/icons/SlashIcon';

const CurrentScreenIndicator = props => {
    // ********** CurrentScreenIndicator props **********
    const { currentScreen, fadeDuration } = props;
    // ********** useState hooks **********
    const [fadeIndicatorIn, setFadeIndicatorIn] = useState(false);
    const [indicatorVisible, setIndicatorVisible] = useState(false);
    // ********** useSelector hooks **********
    const totalChapters = useSelector(state => state.screenMediaLoaded.length);
    // ********** useLayoutEffect hook **********
    useLayoutEffect(() => {
        const indicatorVisibleTimeout = setTimeout(() => {
            setFadeIndicatorIn(true);
            setTimeout(() => {
                setFadeIndicatorIn(false);
                setIndicatorVisible(true);
            }, fadeDuration * 1000);
        }, 10);
        return () => {
            clearTimeout(indicatorVisibleTimeout);
        };
    }, [ fadeDuration ]);

    const currentChapterAdjusted = currentScreen + 1;
    const zIndex = totalChapters + 1;
    const currentChapterForDisplay = (currentChapterAdjusted > 0) ? currentChapterAdjusted : 1;
    const currentChapter = convertNumberToString(currentChapterForDisplay);
    const totalChaptersString = convertNumberToString(totalChapters);
    
    return (
        <div
            className={`currentSectionIndicator${!!fadeIndicatorIn ? ' fadingIn' : ''}${!!indicatorVisible ? ' visible' : ''}`}
            style={{ zIndex }}
        >
            <div className='flexBox'>
                <span className='currentChapter'>{currentChapter}</span>
                <span className='solidus'><SlashIcon /></span>
                <span className='totalChapters'>{totalChaptersString}</span>
            </div>
        </div>
    );
};

CurrentScreenIndicator.propTypes = {
    currentScreen: PropTypes.number.isRequired,
    fadeDuration: PropTypes.number.isRequired
};

export default CurrentScreenIndicator;