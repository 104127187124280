import parseLocation from "./parseLocation";

/**
 * Determines whether or not the user is currently within one of the app.’s legal pages.
 * @param {object} locationObj Optional. User can pass in a window.location object. If he or she doesn’t, the function will create one based on the user’s current window location.
 * @returns {object || null} If the user is in one of the app.’s legal pages, an object will be returned containing the name of the sub-section and whether or not it’s the sub-section’s home page (home pages don't render their section’s sub-navigation menus). If the user isn’t in one of the app.’s sub-sections, null will be returned.
 */
export default function determineIfLegalPage(locationObj = parseLocation()) {
    // console.log('locationObj: ', locationObj);
    const pathname = locationObj.pathname.split('/');
    // console.log('Legal pathname: ', pathname);
    // console.log('Legal pathname.length: ', pathname.length);
    const legalPage = (
        pathname.length === 3 &&
        pathname[2] === '' &&
        pathname[1] === 'privacy-policy'
    );
    return legalPage;
};