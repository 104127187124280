import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import getBreakpoint from '../../../utilities/misc/getBreakpoint';
import getBackgroundMediaSettings from '../../../utilities/misc/getBackgroundMediaSettings';

/**
 * ALERT: This component can be totally reused throughout the app.
 */

const getImageBackgroundSettings = backgroundImagesArray => {
    const currentBreakpoint = getBreakpoint(window.innerWidth);
    return getBackgroundMediaSettings(backgroundImagesArray, currentBreakpoint);
};

const ResponsiveBackgroundImage = props => {
    // console.log('ResponsiveBackgroundImage props: ', props);
    const { backgroundImagesArray } = props;
    const [
        imageBackgroundSettings,
        setImageBackgroundSettings
    ] = useState(getImageBackgroundSettings(backgroundImagesArray));
    useEffect(() => {
        const setImageBackgroundSettingsOnWindowResize = () => setImageBackgroundSettings(getImageBackgroundSettings(backgroundImagesArray));
        window.addEventListener('resize', setImageBackgroundSettingsOnWindowResize);
        return () => window.removeEventListener('resize', setImageBackgroundSettingsOnWindowResize);
    });
    return (
        <div className='backgroundImageContainer'>
            <img
                src={`${process.env.PUBLIC_URL}${backgroundImagesArray[imageBackgroundSettings.indexValue]}`}
                alt=''
                className={`backgroundImage${imageBackgroundSettings.breakpointClassname}`}
            />
        </div>
    );
};

ResponsiveBackgroundImage.propTypes = {
    backgroundImagesArray: PropTypes.array.isRequired
};

export default ResponsiveBackgroundImage;