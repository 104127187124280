import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import ReactDOM from 'react-dom';

import './assets/scss/fonts.scss';
import './assets/scss/index.scss';
import './index.scss';

import getNavigationMenus from './requests/getNavigationMenus';
import jackmanStore from './redux';
import navigationReceivedActionCreator from './redux/actionCreators/navigationReceivedActionCreator';
import currentScreenActionCreator from './redux/actionCreators/currentScreenActionCreator';
import currentAPositionActionCreator from './redux/actionCreators/currentAPositionActionCreator';
import isMobileDevice from './utilities/misc/isMobileDevice';
import parseLocation from './utilities/misc/parseLocation';
import fixSlug from './utilities/misc/fixSlug';
import determineIfSubSection from './utilities/misc/determineIfSubSection';
import determineIfLegalPage from './utilities/misc/determineIfLegalPage';

import Header from './components/ui/Header';
import Cookies from './components/ui/Cookies';
import Cover from './components/ui/Cover';
import StaticTemplate from './page-types/static';
import LegalTemplate from './page-types/legal';
import AnimatedTemplate from './page-types/animated';

class JackmanReinvents extends React.Component {

    state={
        showLoadingScreen: true,
        loadingScreenTransitioning: false,
        resizeTimeout: null,
        navigationOpen: false,
        headerHidden: false,
        headerDark: false,
        showCookies: true,
        currentScreen: 0
    };

    // Mutable properties that don’t require a state re-rendering
    routes = {};
    lastScrollTop = 0;
    resetHash = false;

    setNavigationOpen = bool => this.setState({ ...this.state, navigationOpen: bool });

    setShowLoadingScreen = bool => {
        this.resetHash = true;
        if (!bool) {
            this.setState({ ...this.state, loadingScreenTransitioning: true });
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    showLoadingScreen: bool,
                    loadingScreenTransitioning: bool
                });
            }, 500);
        } else
            this.setState({ ...this.state, showLoadingScreen: bool });
    };

    determineLoadingScreenState = () => {
        const hashValue = parseInt(window.location.href.split('#')[1]);
        if (isNaN(hashValue) || hashValue === 1) {
            /**
             * After a delay of 0.75 seconds...
             */
            setTimeout(() => {
                /**
                 * ...remove the loading screen.
                 * 
                 * The delay is needed to ensure that all reminents of the previous page have been replaced with the new page’s content.
                 */
                this.setShowLoadingScreen(false);
            }, 750);
        }
    };

    setCurrentScreen = currentScreen => this.setState({ ...this.state, currentScreen });
    
    scrollDirection = () => {
        /**
         * Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
         */
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollTop > this.lastScrollTop)
            // Scrolling down
            this.setState({ ...this.state, headerHidden: true });
        else
            // Scrolling up
            this.setState({ ...this.state, headerHidden: false });
        const screenHeight = window.innerHeight;
        /**
         * If the currentScrollTop position is greater than the total number of pixels in the inner height of the browser window...
         */
        if (currentScrollTop > screenHeight)
            // ...make the header’s background-color opaque.
            this.setState({ ...this.state, headerDark: true });
        /**
         * Else if the currentScrollTop position is less than the total number of pixels in the inner height of the browser window...
         */
        else
            // ...make the header’s background-color semi-transparent.
            this.setState({ ...this.state, headerDark: false });
        /**
         * For Mobile or negative scrolling:
         */
        this.lastScrollTop = (currentScrollTop <= 0) ? 0 : currentScrollTop;
    };

    /**
     * Determines the current screen that the user has scrolled into.
     * @param {number} currentAPosition – The current augmented Y axis 
     * position of the browser window.
     * @returns {number} – The index value of the current chapter the 
     * top of the browser is currently in.
     */
    determineCurrentScreen = currentAPosition => {
        const screenStartPixels = jackmanStore.getState().screenStartPixels;
        /**
         * Create a copy of the starting pixel points array for each 
         * chapter and flip their values from last to first.
         * @type {Array<number>}
         */
        const screenStartPixelsFlipped = screenStartPixels.slice().reverse();
        /**
         * Find the start pixel value of the chapter the browser is 
         * currently in.
         * @type {number}
         */
        const currentScreenStartPixel = screenStartPixelsFlipped.find(sspf => {
            return currentAPosition >= sspf;
        });
        /**
         * Get the index value of the chapter the browser is currently 
         * in.
         * @type {number}
         */
        const currentScreen = screenStartPixels.findIndex(ssp => {
            return ssp === currentScreenStartPixel;
        });
        return currentScreen;
    };

    /**
     * Changes the currentScreen property in the store if the browser 
     * has scrolled into a new screen.
     * @returns {void}
     */
    changeCurrentScreen = () => {
        const currentAPosition = jackmanStore.getState().currentAPosition;
        const prevCurrentScreen = this.state.currentScreen;
        const currentScreen = this.determineCurrentScreen(currentAPosition);
        currentScreen !== prevCurrentScreen &&
            jackmanStore.dispatch(currentScreenActionCreator(currentScreen));
    };

    /**
     * Parallax methods to run as the user scrolls.
     * NOTE: You might be able to dump this if the only method you 
     * need to call as the user scrolls is changeCurrentScreen().
     * @returns {void}
     */
    listenToScroll = () => {
        const { screens } = jackmanStore.getState();
        const screenHeight = window.innerHeight;
        const staticPage = screens.length === 0;
        if (!staticPage) {
            // ALERT: Might not need this anymore
            if (this.state.headerHidden) {
                this.lastScrollTop = 0;
                this.setState({ ...this.state, headerHidden: false });
            }
            /**
             * ALERT: Here is the problem with the overscrolling that Dustin is reporting. If the screen’s height is taller than 1080px, then you need to take into consideration the dead area pixels above and below the middle 1080px.
             */
            const currentAPosition = window.scrollY + (screenHeight / 2);
            jackmanStore.dispatch(currentAPositionActionCreator(currentAPosition));
            this.changeCurrentScreen();
        } else
            this.scrollDirection();
    };

    /**
     * Runs if the user resizes their browser window. Disconnects the 
     * events that have been connected to the window that are needed 
     * for the parallax experience.
     * @returns {void}
     */
    windowResized = () => {
        const screens = jackmanStore.getState().screens;
        const staticPage = screens.length === 0;
        if (!staticPage) {
            jackmanStore.dispatch({ type: 'RESIZED' });
            switch (isMobileDevice()) {
                // Remove the mobile specific event listeners
                case true: {
                    window.removeEventListener('orientationchange', this.windowResized);
                    break;
                }
                // Remove the desktop specific event listeners
                default: {
                    window.removeEventListener('resize', this.windowResized);
                }
            }
            window.removeEventListener('scroll', this.listenToScroll);
            window.removeEventListener('keydown', this.disableDownKey);
            clearTimeout(this.state.resizeTimeout);
            this.setState({
                ...this.state,
                resizeTimeout: setTimeout(() => {
                    window.location.reload();
                }, 1000)
            });
        }
    };

    composeStaticRoute = ({
        keyId,
        page,
        staticPage,
        subSection,
        subNavigation,
        footerContent
    }) => {
        return (
            <Route
                key={`route-${keyId}`}
                exact
                path={page.href}
                render={props => <StaticTemplate history={props.history} staticPage={staticPage} page={`${page.slug}`} setShowLoadingScreen={this.setShowLoadingScreen} subSection={subSection} subNavigation={subNavigation} footerContent={footerContent} />}
            />
        );
    };

    composeLegalRoute = ({
        keyId,
        page
    }) => {
        return (
            <Route
                key={`route-${keyId}`}
                exact
                path={page.href}
                render={() => <LegalTemplate page={`${page.slug}`} setShowLoadingScreen={this.setShowLoadingScreen} />}
            />
        );
    };

    generateRoutes = currentRoutes => {
        const locationObj = parseLocation();
        if (locationObj.pathname.slice(-1) !== '/') {
            locationObj.pathname = `${locationObj.pathname}/`;
        }
        if (locationObj.slug === '')
            locationObj.slug = fixSlug(locationObj);
        /**
         * Add a route if the location in the location bar isn’t present (in case it's an “orphan” page)...
         */
        const isPathnamePresent = currentRoutes[this.props.globalNavigationMenu].pages.find(page => {
            return page.href === locationObj.pathname;
        });
        currentRoutes[this.props.globalNavigationMenu].pages = (!isPathnamePresent) ?
                [
                    {
                        href: locationObj.pathname,
                        orphan: true,
                        slug: locationObj.slug
                    },
                    ...currentRoutes[this.props.globalNavigationMenu].pages
                ]
            : currentRoutes[this.props.globalNavigationMenu].pages;
        /**
         * NOTE: There's got to be a cleaner way to do this, but it works and I'm tired. I'll figure it out tomorrow...
         * 
         * Maybe create an array with all of the page objects in it, and then use .map from there?
         */
        const routes = [];
        this.props.navigationMenus.forEach((menu, i) => {
            if (menu === 'navigation-our-work') {
                currentRoutes[menu].pages.forEach((page, j) => {
                    routes.push(
                        this.composeStaticRoute({
                            keyId: `${i}-${j}`,
                            page,
                            staticPage: 'OurWorkDetail',
                            subSection: 'our-work',
                            subNavigation: currentRoutes[menu].pages
                        })
                    );
                });
            } else if (menu === 'navigation-our-leaders') {
                currentRoutes[menu].pages.forEach((page, j) => {
                    routes.push(
                        this.composeStaticRoute({
                            keyId: `${i}-${j}`,
                            page,
                            staticPage: 'OurLeadersDetail',
                            subSection: 'our-leaders',
                            subNavigation: currentRoutes[menu].pages,
                            footerContent: currentRoutes[menu].contact
                        })
                    );
                });
            } else if (menu === 'navigation-our-services') {
                currentRoutes[menu].pages.forEach((page, j) => {
                    routes.push(
                        this.composeStaticRoute({
                            keyId: `${i}-${j}`,
                            page,
                            staticPage: 'OurServicesDetail',
                            subSection: 'our-services',
                            subNavigation: currentRoutes[menu].pages
                        })
                    );
                });
            } else {
                currentRoutes[menu].pages.forEach((page, j) => {
                    if (page.slug === 'our-work') {
                        routes.push(
                            this.composeStaticRoute({
                                keyId: `${i}-${j}`,
                                page,
                                staticPage: 'OurWorkHome',
                                subNavigation: currentRoutes[`navigation-${page.slug}`].pages
                            })
                        );
                    } else if (page.slug === 'our-leaders') {
                        routes.push(
                            this.composeStaticRoute({
                                keyId: `${i}-${j}`,
                                page,
                                staticPage: 'OurLeadersHome',
                                subNavigation: currentRoutes[`navigation-${page.slug}`].pages,
                                footerContent: currentRoutes[`navigation-${page.slug}`].contact
                            })
                        );
                    } else if (page.slug === 'our-services') {
                        routes.push(
                            this.composeStaticRoute({
                                keyId: `${i}-${j}`,
                                page,
                                staticPage: 'OurServicesHome',
                                subNavigation: currentRoutes[`navigation-${page.slug}`].pages
                            })
                        );
                    } else if (page.legal) {
                        routes.push(
                            this.composeLegalRoute({
                                keyId: `${i}-${j}`,
                                page
                            })
                        );
                    } else {
                        routes.push(
                            <Route
                                key={`route-${i}-${j}`}
                                exact
                                path={page.href}
                                render={props => <AnimatedTemplate history={props.history} page={`${page.slug}`} showLoadingScreen={this.state.showLoadingScreen} setShowLoadingScreen={this.setShowLoadingScreen} determineLoadingScreenState={this.determineLoadingScreenState} disableScollify={this.state.navigationOpen} resetHash={this.resetHash} setCurrentScreen={this.setCurrentScreen} />}
                            />
                        );
                    }
                });
            }
        });
        return routes;
    };

    titleCaseName = text => {
        const name = text.split(' ').map(p => p.toLowerCase().substring(0, 1).toUpperCase() + p.substring(1)).join(' ');
        return name;
    };

    disableDownKey = event => {
        const screens = jackmanStore.getState().screens;
        const staticPage = screens === 0;
        !staticPage && [40].indexOf(event.keyCode) > -1 &&
            event.preventDefault();
    };

    assembleNavigationObject = (content, type) => {
        const navigation = {};
        const isGlobalNavigationMenu = (type === this.props.globalNavigationMenu);
        navigation.pages = content.pages.map((page, i) => {
            const href = (isGlobalNavigationMenu && !page.slug) ?
                    `${page.url}`
                : (isGlobalNavigationMenu) ?
                        `/${page.slug !== 'homepage' ? page.slug + '/' : ''}`
                    : `/${page.slug}/`;
            const name = this.titleCaseName(page.navigationName);
            const heading = page.pageHeading || null;
            const thumbnails = page.thumbnails  || null;
            const subPage = page.subPage || null;
            const legal = page.legal || null;
            return {
                href,
                name,
                heading,
                thumbnails,
                slug: page.slug,
                subPage,
                legal
            };
        });
        if (!!content.contact)
            navigation.contact = content.contact;
        return navigation;
    };

    receivedNavigationRoutes = receivedNavigationMenus => {
        const combinedNavigationMenus = {};
        this.props.navigationMenus.forEach(nav => {
            combinedNavigationMenus[nav] = this.assembleNavigationObject(receivedNavigationMenus[nav], nav);
        });
        const globalNavigationObj = { ...combinedNavigationMenus[this.props.globalNavigationMenu] };
        const updatedGlobalNavigationRoutesOrder = [
            ...combinedNavigationMenus[this.props.globalNavigationMenu].pages.slice(1),
            ...combinedNavigationMenus[this.props.globalNavigationMenu].pages.slice(0, 1)
        ];
        combinedNavigationMenus[this.props.globalNavigationMenu].pages = updatedGlobalNavigationRoutesOrder;
        this.routes = { ...combinedNavigationMenus };
        /**
         * ALERT: We can likely remove this dispatch as the navigation object should come out of the redux store...
         */
        jackmanStore.dispatch(navigationReceivedActionCreator(globalNavigationObj));
    };

    componentDidMount() {
        jackmanStore.subscribe(() => this.forceUpdate());
        switch (isMobileDevice()) {
            // Add the mobile specific event listeners
            case true: {
                window.addEventListener('orientationchange', this.windowResized);
                break;
            }
            // Add the desktop specific event listeners
            default: {
                window.addEventListener('resize', this.windowResized);
            }
        }
        window.addEventListener('scroll', this.listenToScroll);
        window.addEventListener('keydown', event => this.disableDownKey(event), false);
        /**
         * Going to grab the navigation A.P.I. here and assemble the Routes below.
         */
        getNavigationMenus(this.props.navigationMenus, content => this.receivedNavigationRoutes(content));
        // Determine whether to show the cookies window.
        if (window.localStorage.getItem('storeCookies'))
            this.setState({ ...this.state, showCookies: false });
    }

    componentWillUnmount() {
        if (!!isMobileDevice()) {
            window.removeEventListener('orientationchange', this.windowResized);
        } else
            window.removeEventListener('resize', this.windowResized);
        window.removeEventListener('scroll', this.listenToScroll);
        window.removeEventListener('keydown', this.disableDownKey);
    }

    render() {
        // ********** Redux state props: **********
        const {
            screens,
            resized,
            toneAnimating
        } = jackmanStore.getState();

        const bgToneClass = !!screens[this.state.currentScreen] ? `backgroundTone-${screens[this.state.currentScreen].background[0].tone}` : '';
        const toneAnimatingClass = !!toneAnimating ? ' toneAnimating' : '';

        return (
            <div
                className={`body${!!screens[this.state.currentScreen] ? ' ' + bgToneClass + toneAnimatingClass : ''}`}
                ref='body'
            >
                <div className={`contentWrapper${!!determineIfSubSection() ? ' subSection' : determineIfLegalPage() ? ' legalPage' : ' animatedPage'}`}>
                    <Provider store={jackmanStore}>
                        {
                            Object.entries(this.routes).length !== 0 &&
                                <Router>
                                    <Route
                                        render={props => <Header navigations={this.routes} globalNavigationMenu={this.props.globalNavigationMenu} setNavigationOpen={this.setNavigationOpen} match={props.match} history={props.history} setShowLoadingScreen={this.setShowLoadingScreen} headerHidden={this.state.headerHidden}  headerDark={this.state.headerDark} />}
                                    />
                                    <Switch>
                                        {this.generateRoutes(this.routes)}
                                        <Redirect to='/#1' />
                                    </Switch>
                                </Router>
                        }
                    </Provider>
                    {
                        this.state.showCookies &&
                            <Cookies />
                    }
                    <Cover
                        resizing={resized}
                        animatedScreen={screens[this.state.currentScreen]}
                        loading={this.state.showLoadingScreen}
                        transitioning={this.state.loadingScreenTransitioning}
                    />
                </div>
            </div>
        );
    }
}

JackmanReinvents.propTypes = {
    globalNavigationMenu: PropTypes.string.isRequired,
    navigationMenus: PropTypes.array.isRequired
};

JackmanReinvents.defaultProps = {
    globalNavigationMenu: 'navigation',
    navigationMenus: [
        'navigation-our-work',
        'navigation-our-leaders',
        'navigation-our-services',
        'navigation'
    ]
};

ReactDOM.render(<JackmanReinvents />, document.getElementById('root'));