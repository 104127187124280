import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './ScreenTextBlock.scss';

import getBreakpoint from '../../../../utilities/misc/getBreakpoint';
import contentScaling from '../../../../utilities/misc/contentScaling';

import Headline from './Headline';
import TwoColumn from './TwoColumn';
import CallToAction from './CallToAction';
import SocialFooter from '../SocialFooter';

const ScreenTextBlock = props => {
    const textContainer = useRef(null);
    const {
        screenType,
        mainText,
        color,
        social
    } = props;

    const currentBreakpoint = getBreakpoint(window.innerWidth);
    const textContainerWidth = textContainer.current ? textContainer.current.getBoundingClientRect().width : 0;
    const fontSize = (currentBreakpoint !== 'largeDesktop') ? contentScaling(currentBreakpoint, textContainerWidth) : null;

    const headingsMasksLength = (screenType === 'headline') ?
            mainText.length
        : (screenType === 'two-column') ?
                mainText.headline.length
            : (screenType === 'call-to-action') ?
                    mainText.heading.text.length
                : 0;
    const headingsMasksLengthClass = (headingsMasksLength > 0) ? ` headings-${headingsMasksLength}` : '';

    return (
        <div className={`screenTextBlock${headingsMasksLengthClass}`}>
            <div
                className={`screenType ${screenType}`}
                ref={textContainer}
                style={{ fontSize }}
            >
                <div className='flexColumnSocialFooterSpacerTop' />
                {
                    screenType === 'headline' &&
                        <Headline
                            mainText={mainText}
                            color={color}
                        />
                }
                {
                    screenType === 'two-column' &&
                        <TwoColumn
                            mainText={mainText}
                            color={color}
                        />
                }
                {
                    screenType === 'call-to-action' &&
                        <CallToAction
                            mainText={mainText}
                            color={color}
                        />
                }
                <div className='flexColumnSocialFooterSpacerBottom'>
                    {
                        !!social &&
                            <SocialFooter
                                color={color}
                                text={social.text}
                                networks={social.networks}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

ScreenTextBlock.propTypes = {
    screenType: PropTypes.string.isRequired,
    mainText: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    color: PropTypes.string.isRequired,
    social: PropTypes.object
};

export default ScreenTextBlock;