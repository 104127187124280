/**
 * @param {boolean} toneAnimating
 * @returns {object}
 */
function navigationReceivedActionCreator(navigation) {
    return {
        type: 'NAVIGATION_RECIEVED',
        navigation
    };
}

export default navigationReceivedActionCreator;