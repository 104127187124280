import React, { useState } from 'react';

import './Cookies.scss';

const setLocalStorage = (event, setCloseCookies) => {
    event.preventDefault();
    window.localStorage.setItem('storeCookies', true);
    setCloseCookies(' hideCookies');
};

const Cookies = () => {
    const [ closeCookies, setCloseCookies ] = useState('');
    
    return (
        <div
            className={`cookies${closeCookies}`}
            role='dialog'
        >
            <div>
                <p>This website uses cookies to ensure you get the best experience on our website. <a href='https://cookiesandyou.com/' target='_blank' rel='noopener noreferrer'>Learn More</a></p>
                <button
                    onClick={e => setLocalStorage(e, setCloseCookies)}
                >
                    Got it
                </button>
            </div>
        </div>
    );
};

export default Cookies;