import React from 'react';

const HamburgerRects = () => (
    <g>
        <rect y="6.5" width="33" height="1.5" />
        <rect y="15.75" width="33" height="1.5" />
        <rect y="25" width="33" height="1.5" />
    </g>
);

export default HamburgerRects;