import React from 'react';
import PropTypes from 'prop-types';

import '../../../assets/scss/loadingSpinner.scss';

import './Cover.scss';

const Cover = props => {
    const {
        resizing,
        animatedScreen,
        loading,
        transitioning
    } = props;
    
    return (
        <div className={`cover${((resizing && !!animatedScreen) || loading) ? ' show' : ''}${transitioning ? ' transitioning' : ''}`}>
            <div>
                <div className='loading-spinner'></div>
            </div>
        </div>
    )
};

Cover.propTypes = {
    resizing: PropTypes.bool,
    animatedScreen: PropTypes.object,
    loading: PropTypes.bool,
    transitioning: PropTypes.bool
};

Cover.defaultProps = {
    resizing: false,
    animatedScreen: null,
    loading: true,
    transitioning: false
};

export default Cover;