export default function jackmanStoreReducer(state, action) {
    switch (action.type) {
        case 'NAVIGATION_RECIEVED': {
            const navigation = action.navigation;
            return {
                ...state,
                navigation
            };
        }
        case 'SCREEN_CONTENT_RECEIVED': {
            const screens = action.screens;
            const screenMediaLoaded = screens.map(screen => {
                return false;
            });
            const screenStartPixels = screens.map(screen => {
                return 0;
            });
            return {
                ...state,
                screens,
                screenMediaLoaded,
                screenStartPixels
            };
        }
        case 'SCREEN_CONTENT_RESET': {
            const screens = [];
            const screenMediaLoaded = [];
            const screenStartPixels = [];
            return {
                ...state,
                screens,
                screenMediaLoaded,
                screenStartPixels
            };
        }
        case 'MEDIA_LOADED': {
            const screenMediaLoaded = state.screenMediaLoaded;
            screenMediaLoaded[action.screen] = true;
            return {
                ...state,
                screenMediaLoaded
            };
        }
        case 'SCREEN_HEIGHT': {
            const screenHeight = action.screenHeight;
            /**
             * ALERT: Here is the problem with the overscrolling that Dustin is reporting. If the screen's height is taller than 1080px, then you need to take into consideration the dead area pixels above and below the middle 1080px.
             */
            const screenStartPixels = state.screenMediaLoaded.map((screen, i) => {
                return i * screenHeight;
            });
            return {
                ...state,
                screenHeight,
                screenStartPixels
            };
        }
        case 'TONE_ANIMATING': {
            const toneAnimating = action.toneAnimating;
            return {
                ...state,
                toneAnimating
            };
        }
        case 'RESIZED': {
            const resized = true;
            return {
                ...state,
                resized
            };
        }
        case 'CURRENT_SCREEN': {
            const currentScreen = action.currentScreen;
            return {
                ...state,
                currentScreen
            };
        }
        case 'CURRENT_A_POSITION': {
            const currentAPosition = action.currentAPosition;
            return {
                ...state,
                currentAPosition
            };
        }
        case 'CURRENT_PAGE': {
            const currentPage = action.currentPage;
            return {
                ...state,
                currentPage
            };
        }
        default:
            return state;
    }
}