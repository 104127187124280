import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import getPageContent from '../../requests/getPageContent';
import screenContentResetActionCreator from '../../redux/actionCreators/screenContentResetActionCreator';

import Cover from '../../components/ui/Cover';
import Legal from '../../components/legal/Legal';

function hideLoadingScreen(setShowLoadingScreen) {
    setShowLoadingScreen(false);
}

function requestPageContent({
    page,
    setPageContent,
    setShowLoadingScreen,
    dispatch
}) {
    getPageContent(page, content => {
        setPageContent({ ...content });
        hideLoadingScreen(setShowLoadingScreen);
        dispatch(screenContentResetActionCreator());
    });
}

const LegalTemplate = props => {
    const { page, setShowLoadingScreen } = props;
    // ********** useState Hooks: **********
    const [ pageContent, setPageContent ] = useState({});
    // ********** React-Redux Hooks: **********
    const dispatch = useDispatch();
    useEffect(() => {
        requestPageContent({
            page,
            setPageContent,
            setShowLoadingScreen,
            dispatch
        });
    }, [ page, setShowLoadingScreen, dispatch ]);

    if (Object.entries(pageContent).length === 0) {
        return (
            <Cover />
        );
    } else {
        return (
            <Legal
                headline={pageContent.mainText.headline}
                body={pageContent.mainText.paragraphs}
                endOfStatement={pageContent.mainText.endOfStatement}
            />
        );
    }
};

LegalTemplate.propTypes = {
    page: PropTypes.string.isRequired,
    setShowLoadingScreen: PropTypes.func.isRequired
};

export default LegalTemplate;