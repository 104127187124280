import parseLocation from "../../utilities/misc/parseLocation";

/**
 * Slices the trailing “/” character off of the window.location.pathname string (if there is one) and returns the new string as a slug. Makes it easier to determine whether the user is on the homepage or on a sub-section’s details page.
 * @param {object} locationObj Optional. User can pass in a window.location object. If he or she doesn’t, the function will create one based on the user’s current window location.
 * @returns {string} A slug string that doesn’t contain a trailing “/” character.
 */
export default function fixSlug(locationObj = parseLocation()) {
    let slug = locationObj.pathname.substring(1);
    slug = (slug.slice(-1) === '/') ? slug.slice(0, -1) : slug;
    // console.log('slug: ', slug);
    return slug;
}