import React from 'react';
import PropTypes from 'prop-types';

import './SingleImageBlock.scss';

const Single = props => (
    // console.log('Single props: ', props);
    <figure className='imageArea single'>
        <div className='image'>
            <img
                src={props.image}
                alt=''
            />
        </div>
    </figure>
);

Single.propTypes = {
    image: PropTypes.string.isRequired
};

export default Single;