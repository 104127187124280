import { createStore } from "redux";

import jackmanStoreReducer from "./jackmanStoreReducer";

const initialState = {
    screens: [],
    screenMediaLoaded: [
        // Has media for Screen 1 fully loaded?
        // false,
        // Has media for Screen 2 fully loaded?
        // false,
        // Has media for Screen 3 fully loaded?
        // false,
        // Has media for Screen 4 fully loaded?
        // false,
        // Has media for Screen 5 fully loaded?
        // false
    ],
    // The height, in pixels, of the browser window on load
    screenHeight: 0,
    screenStartPixels: [
        // The y-position of Screen 1
        // 0,
        // The y-position of Screen 2
        // 0,
        // The y-position of Screen 3
        // 0,
        // The y-position of Screen 4
        // 0,
        // The y-position of Screen 5
        // 0
    ],
    // Has the user resized their browser?
    resized: false,
    // The index value for the currently visible screen
    currentScreen: 0,
    // The current window.scrollY value (DON’T REMOVE THIS YET!!! Likely still going to need this)
    currentYPosition: 0,
    // The augmented value of the current window.scrollY value
    currentAPosition: 0,
    toneAnimating: false,
    /**
     * ALERT: You can likely get rid of this navigation object. That can be passed into <Header /> via 
     */
    navigation: {},
    currentPage: 'Homepage'
}

const jackmanStore = createStore(jackmanStoreReducer, initialState);

export default jackmanStore;