import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import './Legal.scss';

import markupHeadingToStringNoParsing from '../../../utilities/misc/markupHeadingToStringNoParsing';
import markupListItemsToStringNoParsing from '../../../utilities/misc/markupListItemsToStringNoParsing';

const markUpBody = body => {
    const bodyMarkup = body.map(textBlock => {
        if (textBlock.h2)
            return ReactHtmlParser(`<h2 class='legal__h2'>${markupHeadingToStringNoParsing(textBlock.h2)}</h2>`);
        else if (textBlock.h3)
            return ReactHtmlParser(`<h3 class='legal__h3'>${markupHeadingToStringNoParsing(textBlock.h3)}</h3>`);
        else if (textBlock.ul)
            return ReactHtmlParser(`<ul class='legal__ul'>${markupListItemsToStringNoParsing(textBlock.ul)}</ul>`)
        else
            return ReactHtmlParser(`<p class='legal__p'>${markupHeadingToStringNoParsing(textBlock.paragraph)}</p>`);
    });
    return bodyMarkup;
};

const Legal = props => {
    const { headline, body, endOfStatement } = props;

    return (
        <div className='legal'>
            <article className='legal__article'>
                <h1
                    className='legal__h1'
                    dangerouslySetInnerHTML={{ __html: markupHeadingToStringNoParsing(headline) }}
                />
                <div className='legal__body'>
                    {
                        markUpBody(body)
                    }
                    <p className='legal__p legal__endOfStatement'>{endOfStatement}</p>
                </div>
            </article>
        </div>
    );
};

Legal.propTypes = {
    headline: PropTypes.array.isRequired,
    body: PropTypes.array.isRequired,
    endOfStatement: PropTypes.string.isRequired
};

export default Legal;