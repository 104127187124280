/**
 * @param {boolean} toneAnimating
 * @returns {object}
 */
function toneAnimatingActionCreator(toneAnimating) {
    return {
        type: 'TONE_ANIMATING',
        toneAnimating
    };
}

export default toneAnimatingActionCreator;