import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './ColumnVideoImageBlock.scss';

import getBreakpoint from '../../../../../utilities/misc/getBreakpoint';
import contentScaling from '../../../../../utilities/misc/contentScaling';

const ColumnVideoImageBlock = props => {
    const columnVideoImageArea = useRef(null);
    const { content } = props;

    const currentBreakpoint = getBreakpoint(window.innerWidth);
    const columnVideoImageAreaWidth = columnVideoImageArea.current ?
            columnVideoImageArea.current.getBoundingClientRect().width
        : 0;
    const fontSize = (currentBreakpoint !== 'largeDesktop') ?
            contentScaling(currentBreakpoint, columnVideoImageAreaWidth)
        : null;

    return (
        <div
            className='columnVideoImageArea'
            ref={columnVideoImageArea}
            style={{ fontSize }}
        >
            {
                !Array.isArray(content.embed) ?
                        <div className='videoContainer'>
                            <iframe
                                src={content.embed}
                                width='100%'
                                height='100%'
                                frameBorder='0'
                                title={content.title}
                                allow='autoplay; fullscreen'
                            />
                        </div>
                    :
                        <figure className='imageContainer'>
                            <img
                                src={currentBreakpoint === 'smartphone' && content.embed.length > 1 ? content.embed[1] : content.embed[0]}
                                alt={content.title}
                                width='100%'
                            />
                        </figure>
            }
        </div>
    );
};

ColumnVideoImageBlock.propTypes = {
    content: PropTypes.object.isRequired
};

export default ColumnVideoImageBlock;