import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import './Hero.scss';

import purifyString from '../../../../../utilities/misc/purifyString';

import ResponsiveBackgroundImage from '../../../../misc/responsive/ResponsiveBackgroundImage';

const Hero = props => {
    const { client, heading, heroBackground, windowHeight } = props;

    const heroStyle = { height: windowHeight.height };

    return (
        <header
            className='hero'
            style={heroStyle}
        >
            <ResponsiveBackgroundImage
                backgroundImagesArray={heroBackground}
            />
            <div className='copy'>
                <div className='cntnr'>
                    <div>
                        <p className='client'>{ReactHtmlParser(client)}</p>
                        <h1 dangerouslySetInnerHTML={{ __html: purifyString(heading) }} />
                    </div>
                </div>
            </div>
        </header>
    );
};

Hero.propTypes = {
    client: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    heroBackground: PropTypes.array.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default Hero;