import React from 'react';
import PropTypes from 'prop-types';

import './ImageBlock.scss';

import Triple from './Triple';
import Double from './Double';
import Single from './Single';

const ImageBlock = props => {
    const { format, settings } = props;
    
    if (format === 'triple') {
        return (
            <Triple
                mainImagePosition={settings.mainImagePosition}
                mainImage={settings.mainImage}
                topImage={settings.topImage}
                bottomImage={settings.bottomImage}
            />
        );
    } else if (format === 'double') {
        return (
            <Double
                mainImagePosition={settings.mainImagePosition || null}
                leftImage={settings.leftImage}
                rightImage={settings.rightImage}
            />
        );
    } else {
        return (
            <Single image={settings.image} />
        );
    }
};

ImageBlock.propTypes = {
    format: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired
};

export default ImageBlock;