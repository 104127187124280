import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import './Hero.scss';

import markupHeadingToStringNoParsing from '../../../../../utilities/misc/markupHeadingToStringNoParsing';
import markupParagraphsToStringNoParsing from '../../../../../utilities/misc/markupParagraphsToStringNoParsing';

import DividerIcon from '../../../../misc/icons/DividerIcon';

const Hero = props => {
    const heading = markupHeadingToStringNoParsing(props.heading);
    const paragraphs = markupParagraphsToStringNoParsing(props.paragraphs);
    const windowHeight = props.windowHeight;
    const heroStyle = { height: windowHeight.height };
    
    return (
        <header
            className='hero-leadership'
        >
            <div
                className='heroLeadershipFlex'
                style={heroStyle}
            >
                <div className='cntnr'>
                    <h1>
                        {
                            ReactHtmlParser(heading)
                        }
                    </h1>
                    <div>
                        {
                            ReactHtmlParser(paragraphs)
                        }
                    </div>
                </div>
            </div>
            <div className='dividerIconWrapper'>
                <DividerIcon />
            </div>
        </header>
    );
};

Hero.propTypes = {
    heading: PropTypes.array.isRequired,
    paragraphs: PropTypes.array.isRequired,
    windowHeight: PropTypes.object.isRequired
};

export default Hero;