import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import requestPageContent from '../../utilities/misc/requestPageContent';
import markupHeadingToStringNoParsing from '../../utilities/misc/markupHeadingToStringNoParsing';
import markupParagraphsToStringNoParsing from '../../utilities/misc/markupParagraphsToStringNoParsing';
import determineCurrentDetail from '../../utilities/misc/determineCurrentDetail';
import getBreakpoint from '../../utilities/misc/getBreakpoint';
import isMobileDevice from '../../utilities/misc/isMobileDevice';

import Cover from '../../components/ui/Cover';
import OurHome from '../../components/static/Our/Home';
import OurDetail from '../../components/static/Our/Detail';
import OurLeadersHome from '../../components/static/OurLeaders/Home';
import OurLeadersDetail from '../../components/static/OurLeaders/Detail';

const determineNextDetail = (details, page) => {
    const currentDetailIndex = determineCurrentDetail(details, page);
    const detailsLength = details.length;
    const nextDetail = (currentDetailIndex === detailsLength - 1) ?
        0
        : currentDetailIndex + 1;
    return details[nextDetail];
};

let currentOrientation = '';

const getCurrentOrientation = () => {
    const orientation = (window.innerHeight > window.innerWidth) ?
            'portrait'
        : 'landscape';
    return orientation;
};

const getWindowHeight = setWindowHeight => {
    const mobileDevice = isMobileDevice();
    if (mobileDevice) {
        const nextOrientation = getCurrentOrientation();
        if (currentOrientation !== nextOrientation) {
            setWindowHeight({
                height: `${window.innerHeight}px`,
                isSmartphone: getBreakpoint(window.innerWidth) === 'smartphone'
            });
            currentOrientation = nextOrientation;
        }
    } else {
        setWindowHeight({
            height: `${window.innerHeight}px`,
            isSmartphone: getBreakpoint(window.innerWidth) === 'smartphone'
        });
    }
};

const StaticTemplate = props => {
    // ********** StaticTemplate props: **********
    const {
        history,
        staticPage,
        page,
        setShowLoadingScreen,
        subSection,
        subNavigation,
        footerContent
    } = props;
    // ********** useState Hooks: **********
    const [ pageContent, setPageContent ] = useState({});
    const [ windowHeight, setWindowHeight ] = useState({
        height: `${window.innerHeight}px`,
        isSmartphone: getBreakpoint(window.innerWidth) === 'smartphone'
    });
    // ********** React-Redux Hooks: **********
    const dispatch = useDispatch();
    // ********** useEffect Hook: **********
    useEffect(() => {
        currentOrientation = getCurrentOrientation();
        window.addEventListener('resize', e => {
            e.preventDefault();
            getWindowHeight(setWindowHeight);
        });
        getWindowHeight(setWindowHeight);
        // If the page is going to be a sub-section page...
        if (subSection)
            requestPageContent({ page, setPageContent, setShowLoadingScreen, dispatch });
        // Else if the page is going to be a home page...
        else {
            const pathnameArray = history.location.pathname.split('/');
            if (
                pathnameArray.length > 3 ||
                (pathnameArray.length === 3 && !!pathnameArray[2])
            )
                history.push(`/${page}/`);
            else
                requestPageContent({ page, setPageContent, setShowLoadingScreen, dispatch });
        }
        return () => window.removeEventListener('resize', getWindowHeight);
    }, [
        dispatch,
        history,
        page,
        setShowLoadingScreen,
        subSection
    ]);

    if (Object.entries(pageContent).length === 0) {
        return (
            <Cover />
        );
    } else {
        if (staticPage === 'OurWorkHome' || staticPage === 'OurServicesHome') {
            const pc = pageContent.mainText;
            const heading = markupHeadingToStringNoParsing(pc.headline);
            const paragraphs = markupParagraphsToStringNoParsing(pc.paragraphs);
            const sponsorsHeading = !!pageContent.partners ?
                    pageContent.partners.imageArea.headline
                : null;
            const sponsorsCta = !!pageContent.partners ?
                    pageContent.partners.cta
                : null;
            const sponsorsLogos = !!pageContent.partners ?
                    pageContent.partners.imageArea.logos
                : null;
            return (
                <OurHome
                    staticPage={staticPage}
                    heading={heading}
                    paragraphs={paragraphs}
                    sponsorsHeading={sponsorsHeading}
                    sponsorsCta={sponsorsCta}
                    sponsorsLogos={sponsorsLogos}
                    subNavigation={subNavigation}
                    windowHeight={windowHeight}
                />
            );
        } else {
            const pc = pageContent;
            if (staticPage === 'OurWorkDetail' || staticPage === 'OurServicesDetail') {
                const nextDetail = determineNextDetail(subNavigation, page);
                return (
                    <OurDetail
                        staticPage={staticPage}
                        pc={pc}
                        nextDetail={nextDetail}
                        windowHeight={windowHeight}
                    />
                );
            } else if (staticPage === 'OurLeadersHome') {
                return (
                    <OurLeadersHome
                        pc={pc}
                        subNavigation={subNavigation}
                        footerContent={footerContent}
                        windowHeight={windowHeight}
                    />
                );
            // Else if staticPage === 'OurLeadersDetail'...
            } else {
                return (
                    <OurLeadersDetail
                        pc={pc}
                        footerContent={footerContent}
                        windowHeight={windowHeight}
                    />
                );
            }
        }
    }
};

StaticTemplate.propTypes = {
    history: PropTypes.object,
    staticPage: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    setShowLoadingScreen: PropTypes.func.isRequired,
    subSection: PropTypes.string,
    subNavigation: PropTypes.array.isRequired,
    footerContent: PropTypes.object
};

export default StaticTemplate;